html {
    box-sizing: border-box;
    //font-size: 62.5%; // 1rem = 10px
    font-size: 52.5%;
    scroll-behavior: smooth;
}

* {
    box-sizing: inherit;

    &::before,
    &::after {
        box-sizing: inherit;
    }
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    background-color: $white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
    color: $black;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color .2s, background-color .2s;

    &:hover {
        text-decoration: none;
        color: inherit;
    }

    &:focus {
        outline: none;
        //text-shadow: 0 0 4px rgba($color: $black, $alpha: .2); 
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

div {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: text;
}

.default-margin {
    margin: $mobile-margin 0;
}

.default-padding {
    padding: $mobile-margin 0;
}

header, footer, .hero-container, .hero-wo-conf-container, section.main-container {
    max-width: $content-width;
    margin: 0 auto;
}

section.main-container {
    background-color: $bgcolor;
    overflow: auto;
}

.lazy {
    display: block;
}


@media (min-width: $row-width) {
    .default-margin {
        margin: $desktop-margin 0;
    }

    .default-padding {
        padding: $desktop-margin 0;
    }

    section.main-container, .thanks-404-container {
        min-height: calc(100vh - 43.4rem);
    }
}