// header

header {
    position: relative;
    width: 100%;
    z-index: 1000;
}

// nav

.menu-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    min-height: 7.8rem;
    transition: height .3s;

    .search-lang {
        font-size: 1.4rem;

        .search-open {
            //margin-right: 1.5rem;
        }

        .icon-globe {
            margin-right: .8rem;
        }
    }

    .menu-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        z-index: 1000;
    }

    .menu-btn-label {
        font-size: 1.4rem;
        margin-right: .7rem;
        transition: color .5s ease;
    }

    .menu-icon {
        $size: 2.4rem;
        width: $size;
        margin-bottom: .3rem;

        &__line {
            height: 3px;
            width: $size;
            display: block;
            background-color: $black;
            margin: .4rem 0;
            transition: transform .2s ease, background-color .5s ease;
        }
    }

    .brand-logo {
        width: 12.5rem;

        img {
            filter: invert(.6);
            width: 100%;
            height: auto;
        }
    }

    nav {
        padding: 0;
        z-index: 1000;

        .nav-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            top: 8rem;
            visibility: hidden;
            opacity: 0;
            width: 100%;
            color: $white;
            transition: visibility $nav-transition-length + s, opacity $nav-transition-length + s;
            transition-delay: 0;
            padding: 0 2rem;

            .nav-list {
                list-style: none;
                margin: 0;
                padding: 1rem 0;
                display: flex;
                flex-direction: column;
                border-top: 1px solid $white;
                border-bottom: 1px solid $white;
                order: 0;
            }

            .nav-list-item {
                display: block;
                font-weight: 400;
                font-size: 2rem;
                line-height: 2.5rem;
                margin: 1.5rem 0;

                &.active {
                    color: $white;
                }
            }

            
            .nav-contact-icons {
                display: flex;
                align-items: center;
                order: 2;
                margin-top: 1.5rem;

                a {
                    font-size: 1.5rem;
                    margin: 0 3.5rem 0 0;
                }
            }

            .nav-contact {
                display: flex;
                flex-direction: column;
                font-size: 1.4rem;
                margin-top: 2.5rem;
                order: 1;
            }

            .nav-contact-item {
                margin-bottom: 1.5rem;

                i {
                    width: 4rem;
                    display: inline-block;
                }
            }

            .nav-policy {
                display: flex;
                justify-content: space-between;
                font-size: 1.4rem;
                margin-top: 3rem;
                margin-bottom: 2rem;
                order: 3;
            }
        }
    }
}

.search-lang-dropdown {
    position: absolute;
    top: 7.8rem;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    height: 6.2rem;    
    background-color: $white;
    transition-property: opacity, transform, visibility;
    transition-duration: .3s; 

    a:hover {
        color: $green;
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }

    .search-box,
    .lang-box {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        padding: 0 1.5rem;
        visibility: hidden;
        opacity: 0;
        align-items: center;
        font-size: 1.6rem;
        transition-property: opacity, transform, visibility;
        transition-duration: .3s;        

        &.open {
            visibility: visible;
            opacity: 1;
        }
    }

    .search-box {
        width: 100%;

        form {
            display: flex;
            align-items: center;
            width: 100%;

            input {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid $black;
                margin: 0 1rem;
                padding: 1rem 1.5rem;
                flex: 1;

                &:focus {
                    box-shadow: none;
                    border-bottom: 1px solid $black;
                    outline: 0 none;
                }
            }
        }
    }

    .lang-box {
        top: .6rem;

        .icon-globe {
            margin-right: 2rem;
        }

        span {
            margin: 0 1rem;
        }

        .lang-close {
            margin-left: 2rem;
        }
    }
}

// NAV bg

.nav-bg:before,
.nav-bg:after {
    $transition--easing: cubic-bezier(.77, 0, .175, 1);
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba($color: $green, $alpha: .5);
    z-index: 900;
    transition: transform $transition--easing $nav-transition-length + s;
    transform: translateX(0%) translateY(-100%);
}

.nav-bg:after {
    background: rgba($color: $green, $alpha: .9);
    transition-delay: .1s;
}

.nav-bg:before {
    transition-delay: .2s;
}

// NAV active

.nav-active {
    .menu-btn-label {
        color: $white;
    }

    .menu-icon {
        &__line {
            transform: translateX(0px) translateY(3px) rotate(-45deg);
            background-color: $white;
        }

        &__line-bottom {
            transform: translateX(0px) translateY(-4px) rotate(45deg);
        }
    }

    .nav-bg {

        &:before,
        &:after {
            transform: translateX(0%) translateY(0%);
        }

        &:after {
            transition-delay: .1s;
        }

        &:before {
            transition-delay: 0s;
        }
    }

    .menu-bar {

        nav {
            .nav-content {
                visibility: visible;
                opacity: 1;
                transition-delay: .4s;
            }
        }
    }
}

@media (min-width: $row-width) {

    .menu-bar {
        padding: 0 3.5rem;
        min-height: 9rem;

        a:hover,
        a:focus {
            color: $green;
        }

        .search-lang {
            font-size: clamp(1.2rem, .7vw, 1.5rem); 
            position: relative;

            .search-open {
                //margin-right: 2rem;
            }

            .icon-globe {
                margin-right: 1rem;
            }
        }

        .brand-logo {
            width: auto;

            img {
                width: 100%;
                height: auto;
            }
        }

        nav {
            flex: 1;
            padding: 0 2.5rem;

            .nav-content {
                position: static;
                visibility: visible;
                opacity: 1;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                color: $black;
                transition: none;
                left: unset;
                top: unset;
                right: unset;
                padding: 0;

                .nav-list {
                    display: block;
                    border: none;
                    padding: 0;
                    order: unset;
                }

                .nav-list-item {
                    display: inline-block;
                    margin: 0 1.29vw;
                    font-size: clamp(1.4rem, 1vw, 1.9rem); 
                    order: unset;

                    &.active,
                    a:hover,
                    a:focus {
                        color: $green;
                    }
                }

                .nav-contact,
                .nav-policy {
                    display: none;
                }

                .nav-contact-icons {   
                    margin-top: 0;
                    
                    a {
                        font-size: clamp(1rem, .6vw, 1.2rem); 
                        margin: 0 1.5rem;
                    }
    
                    .separator {
                        height: 5.6rem;
                        background-color: $black;
                        width: .1rem;
                        margin-left: 1.5rem;
                    }
                }
            }
        }
    }

    .search-lang-dropdown {
        top: 8rem;
        
        .search-box,
        .lang-box {
            padding: 0 3.5rem;
        }

        .search-box {
            width: auto;

            form {
                width: auto;

                input {
                    width: 35rem;
                    flex: unset;
                }
            }
        }
    }
}