button:focus {
    outline: none;
}

.defbtn {
    display: inline-block;
    position: relative;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1em;
    text-transform: uppercase;
    color: $white;
    outline: none;
    padding: 1.7rem 3rem;
    border: none;
    text-align: center;
    background-color: $black2;
    border-radius: 1rem 0;
    transition: background-color .3s, color .3s, filter .3s;
    cursor: pointer;
    filter: brightness(1);

    &:hover, &:focus {
        filter: brightness(1.1);
        color: $white;
    }

    i {
        vertical-align: top;
        font-size: 1rem;
        margin-left: 1rem;
    }

    &.transparent {
        background-color: transparent;
        border: 1px solid $white;
        font-size: 1.2rem;
        padding: 1.3rem 1.3rem 1.3rem 1.5rem;
        white-space: nowrap;

        i {
            vertical-align: top;
            font-size: 1rem;
            margin-left: 1rem;
        }

        &:hover, &:focus {
            background-color: rgba($color: $white, $alpha: .1);
        }
    }

    &.headerbtn {
        font-size: 1.3rem;
        padding: 1.5rem 1.3rem;
        white-space: nowrap;

        i {
            font-size: .8rem;
            vertical-align: top;
            margin-left: 1.5rem;
        }
    }

    &.view-more {
        background-color: $green;
        font-size: 1.4rem;
        padding: 2rem 2.6rem;
        border-radius: 1rem 0;

        i {
            font-size: .8rem;
            vertical-align: top;
            margin-left: 1.5rem;
        }
    }

    &.lil {
        font-size: 1.6rem;
        padding: 1.7rem 3rem;
    }
}

@media (min-width: $row-width) {
    .defbtn {
        font-size: 2.4rem;
        padding: 2.8rem 5rem;
        border-radius: 2rem 0;

        i {
            font-size: 1rem;
            margin-left: 1.4rem;
        }

        &.transparent {
            border: 2px solid $white;
            border-radius: 1rem 0;
            font-size: 1.4rem;
            padding: 1.7rem 1.7rem 1.7rem 2.4rem;

            i {
                font-size: 1rem;
                margin-left: 1.4rem;
            }
        }

        &.headerbtn {
            font-size: clamp(1.3rem, 1.3vw, 2.4rem);
            padding: 2.8rem 2.5rem;
    
            i {
                font-size: 1.2rem;
                margin-left: 4rem;
            }
        }
    }
}