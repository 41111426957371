footer {
    background-color: $white;
    color: $green;
    position: relative;
    padding: 3.5rem 0;

    .footer-brand-logo {
        margin-bottom: 4rem;
    }

    a.footer-contact-item:hover,
    .footer-policy a:hover,
    a.footer-contact-item:focus,
    .footer-policy a:focus {
        color: $black;
    }

    .footer-contact,
    .footer-policy {
        margin-bottom: 2.5rem;
    }

    .footer-policy a,
    .footer-contact-item {
        font-size: 1.2rem;
        white-space: nowrap;
        display: block;
        margin-bottom: 1.2rem;
        min-height: 2.5rem;
    }

    .footer-contact-item {
        i {
            margin-right: 1rem;
            display: inline-block;
            vertical-align: middle;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .footer-social {
        position: relative;

        .footer-simplepay {
            margin-bottom: 3.8rem;
        }
    
        .social-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3rem;
            line-height: 1em;
            color: $green;
            background-color: $white;
            outline: none;
            width: 5rem;
            height: 5rem;
            border: 1px solid $green;
            border-radius: .7em 0;
            transition: background-color .3s, color .3s, filter .3s;
            cursor: pointer;
            margin: 0 1.3rem 0 0;

            &:hover, &:focus {
                color: $white;
                background-color: $green;
            }
        }

        .newsl-open {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 5rem;
            font-size: 1.5rem;
            line-height: 1em;
            color: $white;
            background-color: $blue;
            outline: none;
            border: none;
            border-radius: 0;
            transition: background-color .3s, color .3s, filter .3s;
            cursor: pointer;
        }

        .newsl-box {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            bottom: 7rem;
            left: 0;
            max-width: 40rem;
            background-color: $blue;
            padding: 3rem;
            z-index: 3000;
            transform: translateX(-2rem);
            transition-property: opacity, transform, visibility;
            transition-duration: .3s;

            .newsl-close {
                position: absolute;
                top: .8rem;
                right: 1.5rem;
                color: $black;
                font-size: 1.5rem;
            }

            label {
                color: $white;
            }

            .form-group {
                margin-bottom: 3rem;
            }

            .big-margin {
                margin: 3rem 0 2.5rem;
            }

            .custom-control label {
                font-size: 1.2rem;
            }

            &.visible {
                visibility: visible;
                opacity: 1;
                transform: translateX(0rem);
            }
        }
    }
}

@media (min-width: $row-width) {
    footer {
        padding: 6.6rem 0;

        .footer-brand-logo {
            margin-bottom: 2.5rem;
        }

        .footer-contact-policy-flex {
            display: flex;
            justify-content: space-between;
        }

        .footer-contact,
        .footer-policy {
            margin-bottom: 0;
        }

        .footer-contact,
        .footer-policy,
        .footer-social {
            flex: 0 0 33.33333333%;
        }

        .footer-policy a,
        .footer-contact-item {
            font-size: 1.4rem;
            margin-bottom: 1.5rem;
            min-height: 3rem;
        }

        .footer-policy {
            display: flex;
            justify-content: center;
            margin: 0;
        }

        .footer-social {
            .footer-simplepay {
                text-align: right;
                margin-bottom: 3rem;
            }

            .social-box {
                text-align: right;
            }

            .social-btn {
                font-size: 1.5rem;
                width: 5rem;
                height: 5rem;
                margin: 0 1.3rem 0 0;
            }
    
            .newsl-open {
                width: 5rem;
                height: 5rem;
                font-size: 1.5rem;
            }

            .newsl-box {
                //bottom:10rem;
                right: 0;
                left: unset;
            }
        }
    }
}