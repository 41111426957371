body.popup-open {
    overflow: hidden;
    padding-right: 17px;
}

.team-container {
    position: relative;
    margin: $mobile-margin 0 6rem;

    .team-slider {
        margin: 0 -1.5rem;
        width: calc(100% + 3rem);
    }

    .team-member {
        width: calc(50% - .5rem);
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .team-popup {
        visibility: hidden;
        cursor: pointer;

        &.open {
            visibility: visible;                

            .popup .popup-dialog {
                transform: none;
            }

            .popup-backdrop {
                opacity: .4;
            }
        }

        .popup {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1055;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            outline: 0;
            display: flex;                
            align-items: center;
            justify-content: center;
            padding: 0 1.5rem;

            .popup-dialog {
                max-width: 100rem;
                width: 100%;
                padding: 4rem 2rem 2rem;
                background-color: rgba($color: #DEF1D9, $alpha: .95);
                transform: scale(0.9);
                transition: transform .2s ease-out;
                position: relative;

                .close {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    width: 2rem;
                    height: auto;
                }
            }

            .popup-content {
                //display: flex;                    
            }

            .popup-image {
                width: 100%;
                height: 19.3rem;
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: auto 19.3rem;
                margin-bottom: 2rem;
            }

            h4 {
                font-size: 1.4rem;
                margin-bottom: .4rem;
                font-weight: 500;
                color: $black;
                text-transform: uppercase;
            }
    
            p {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 0;
            }

            hr {
                margin-top: 2rem;
                margin-bottom: 2rem;
                margin-left: 0;
                border: 0;
                border-top: 5px solid $green;
                width: 9rem;
            }

            .job-box {
                display: flex;
                width: 100%;

                p {
                    flex: 1;
                    margin-right: 1.5rem;
                    font-size: 1rem;
                    font-weight: 400;
                    color: $black;
                    margin-bottom: 0;
                }
            }
        }            

        .popup-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1050;
            width: 100vw;
            height: 100vh;
            background-color: #000;
            opacity: 0;
            transition: all .2s;
        }
    }

    .member-image {
        width: 100%;
        height: 19.3rem;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 19.3rem;
    }

    .member-detail {
        position: relative;
        background-color: rgba($color: $green, $alpha: .17);
        padding: 1.3rem 4rem 1.3rem 1rem;
        //flex: 1;

        h4 {
            font-size: 1.2rem;
            font-weight: 500;
            color: $black;
            text-transform: uppercase;
            margin-bottom: .4rem;
        }

        p {
            font-size: .8rem;
            font-weight: 400;
            color: $black;
            margin-bottom: 0;
        }

        .member-linkedin {
            position: absolute;
            bottom: .2rem;
            right: .7rem;
            font-size: 1.2rem;
        }
    }

    .member-hl {
        padding: .5rem 1rem;
        font-size: 1rem;
        font-weight: 700;
        color: $white;
        background-color: $blue;
    }

    &.no-slide {
        .team-slider {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            margin-bottom: 2rem;
        }

        .team-member {
            width: 100%;
            margin-right: 0;
        }
    }
}

@media (min-width: $row-width) {
    .team-container {
        margin: $desktop-margin 0;

        .team-slider, &.no-slide .team-slider {
            /*display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 1.6rem;*/
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            width: 100%;
            gap: 2rem;
        }

        .team-member {
            width: 100%;
            //margin-right: 0;
            flex: 0 0 calc(25% - 6rem / 4);
            margin: 0;
        }

        .team-popup {        
            .popup {   
                padding: 0;
                
                .popup-dialog {
                    padding: 7rem;
    
                    .close {
                        top: 2rem;
                        right: 2rem;
                        width: 2.8rem;
                    }
                }
    
                .popup-content {
                    display: flex;                    
                }
    
                .popup-image {
                    flex: 0 0 29rem;
                    width: 100%;
                    height: 34rem;
                    background-color: $white;
                    background-size: cover;
                    margin-right: 7rem;
                    margin-bottom: 0;
                }
    
                h4 {
                    font-size: 1.8rem;
                }
        
                p {
                    font-size: 1.4rem;
                }
    
                hr {
                    margin-top: 3rem;
                    margin-bottom: 3rem;
                }
    
                .job-box {
                    width: 26rem;
    
                    p {
                        font-size: 1.2rem;
                    }
                }
            }            
    
            .popup-backdrop {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1050;
                width: 100vw;
                height: 100vh;
                background-color: #000;
                opacity: 0;
                transition: all .2s;
            }
        }
    
        .member-image {
            height: 32.7rem;
            background-color: $white;
            background-size: cover;
        }
    
        .member-detail {
            padding: 1.5rem 4rem 1.5rem 2.3rem;
            flex: 1;
    
            h4 {
                font-size: 1.8rem;
                margin-bottom: .4rem;
            }
    
            p {
                font-size: 1.2rem;
            }
    
            .member-linkedin {
                bottom: .5rem;
                right: 1rem;
                font-size: 1.6rem;
            }
        }
    
        .member-hl {
            padding: 1rem 2.3rem;
            font-size: 1.6rem;
        }
    }
}