.about-container {
    position: relative;
    padding: 5.5rem 0 1rem;
    background-color: $blue;
    color: $white;
    overflow: hidden;

    .about-bg-text {
        position: absolute;
        font-size: 11.4rem;
        font-weight: 900;
        top: 1rem;
        left: -2rem;
        opacity: .3;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 1em;
    }

    h1 {
        margin-bottom: 5.5rem;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 3rem;
    }

    .about-block {
        margin-bottom: 3rem;

        .about-text,
        .about-images {
            align-self: center;
        }

        &:nth-of-type(2) {
            .about-text {
                order: 1;
            }

            .about-images {
                order: 2;
            }
        }
    }

    img {
        margin: 0 -1.5rem;
        width: calc(100% + 3rem);
        max-width: calc(100% + 3rem);
        height: auto;
    }
}

@media (min-width: $row-width) {
    .about-container {
        padding: 16rem 0 0;

        .about-bg-text {
            font-size: 30rem;
            top: 3rem;
            left: -10rem;
        }

        h1 {
            margin-bottom: 9rem;
        }

        h3 {
            margin-bottom: 6rem;
        }

        .about-block {
            margin-bottom: 7rem;

            &:nth-of-type(2) {
                .about-text {
                    order: 2;
                }

                .about-images {
                    order: 1;
                }
            }
        }

        img {
            margin: 0;
            width: 100%;
            max-width: 100%;
        }
    }
}