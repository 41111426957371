@font-face {
  font-family: 'fontello';
  src: url('#{$fonts-path}/fontello.eot?71026748');
  src: url('#{$fonts-path}/fontello.eot?71026748#iefix') format('embedded-opentype'),
       url('#{$fonts-path}/fontello.woff2?71026748') format('woff2'),
       url('#{$fonts-path}/fontello.woff?71026748') format('woff'),
       url('#{$fonts-path}/fontello.ttf?71026748') format('truetype'),
       url('#{$fonts-path}/fontello.svg?71026748#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  font-size: 1.5em;
  padding-bottom: 0;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0;

  font-variant: normal;
  text-transform: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
.icon-phone:before { content: '\e800'; } /* '' */
.icon-cancel:before { content: '\e804'; } /* '' */
.icon-cancel-circle:before { content: '\e805'; } /* '' */
.icon-mail:before { content: '\e808'; } /* '' */
.icon-mail-1:before { content: '\e809'; } /* '' */
.icon-globe:before { content: '\e80a'; } /* '' */
.icon-map-marker:before { content: '\e80b'; } /* '' */
.icon-envelope:before { content: '\e80c'; } /* '' */
.icon-linkedin:before { content: '\e80d'; } /* '' */
.icon-peace:before { content: '\e80e'; } /* '' */
.icon-pager:before { content: '\e80f'; } /* '' */
.icon-paper-plane:before { content: '\e810'; } /* '' */
.icon-mask:before { content: '\e811'; } /* '' */
.icon-mobile:before { content: '\e812'; } /* '' */
.icon-mountain:before { content: '\e813'; } /* '' */
.icon-angle-double-down:before { content: '\e814'; } /* '' */
.icon-angle-right:before { content: '\e815'; } /* '' */
.icon-search-1:before { content: '\e816'; } /* '' */
.icon-download:before { content: '\f02e'; } /* '' */
.icon-facebook:before { content: '\f230'; } /* '' */