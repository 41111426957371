.com-prev-container {
    padding: 3rem 0 6rem;

    .cu-container {
        margin-bottom: 5rem;
    }

    .cu-heading, .pre-heading {
        h1 {
            margin: 0 -1.5rem .5rem -1.5rem;
            text-transform: uppercase;
        }

        span {
            font-size: 1.5em;
            font-weight: 900;
            color: $blue;
        }

        h3 {
            font-weight: 700;
            margin-left: 0;
            margin-bottom: 3rem;
        }
    }

    .pre-heading {
        span {
            color: $green;
        }
    }

    .cu-conf-container {
        position: relative;
        margin-bottom: 2rem;

        .conf-img {
            width: calc(100% + 3rem);
            height: 24.2rem;
            margin: 0 -1.5rem;
            background-color: $lightgrey;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .conf-content {
            width: 100%;
            margin: -7.6rem 0 0 0;
            background-color: $blue;
            padding: 2.5rem 1.5rem;
            color: $white;

            h2 {
                margin-bottom: 1.7rem;
            }

            p {
                font-size: 1.2rem;
                line-height: 1.5rem;
                margin-bottom: 1.5rem;
            }

            .offer {
                margin: 2rem 0 0;
            }

            .conf-info {
                margin-bottom: 2.3rem;
            }
        }
    }

    .prev-conf-container {
        display: block;
        margin: 0 -1.5rem;

        .prev-conf-box {
            margin-bottom: 2rem;
            width: 100%;
            margin-right: 1.5rem;
        }

        .prev-conf-img {
            height: 17rem;
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .prev-conf-content {
            background-color: $green;
            color: $white;
            padding: 2.5rem 2rem;

            h3 {
                font-weight: 500;
                text-transform: uppercase;
            }

            p {
                font-size: 1.2rem;
                font-weight: 900;
                margin-bottom: 0;
            }

            .detail {
                .date {
                    padding-bottom: 1rem;
                }
            }
        }
    }
}

@media (min-width: $row-width) {
    .com-prev-container {
        padding: 12rem 0 15rem;

        .cu-container {
            margin-bottom: 10rem;
        }
    
        .cu-heading, .pre-heading {
            h1 {
                margin: 0 0 1.5rem;
            }

            span {
                font-size: 1.9em;
            }
    
            h3 {
                margin-bottom: 2.5em; 
            }
        }
    
        .cu-conf-container {   
            margin-bottom: 10rem;
            
            &.left {
                margin-bottom: 2rem;

                .conf-img {
                    position: relative;
                    margin: 6rem 0 0 auto;
                    //z-index: 200;
                }
    
                .conf-content {
                    left: 0;
                    top: -4rem;
                    right: unset;
                    bottom: unset;
                    background-color: $blue;
                    padding: 5rem 6.5rem 4.4rem;
                    color: $white;
                }
            }
    
            .conf-img {
                width: 50%;
                height: 42rem;
                margin: 0;
            }
    
            .conf-content {
                position: absolute;
                bottom: -4rem;
                right: 0;
                width: 76rem;
                margin: 0;
                padding: 5rem 6.5rem 4.4rem;
    
                h2 {
                    margin-bottom: 3.5rem;
                    max-width: 50rem;
                }
    
                p {
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    margin-bottom: 0;
                }

                .offer {
                    margin: 0;
                }
    
                .conf-info {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2.5rem;
        
                    .date {
                        padding-right: 4rem;
                        border-right: 2px solid $white;
                    }
        
                    .desc {
                        padding-left: 4rem;
                        max-width: 35rem;
                    }
                }
    
                .detail {
                    display: flex;
                    align-items: center;
    
                    .defbtn {
                        margin-right: 4rem;
                    }
                }
            }
        }

        .prev-conf-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
            margin: 0;

            .prev-conf-box {
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
            }
    
            .prev-conf-img {
                height: 32rem;
            }
    
            .prev-conf-content {
                padding: 4rem 6.7rem;
                flex: 1;
    
                p {
                    font-size: 1.6rem;
                }
    
                .detail {
                    display: flex;
                    align-items: center;
    
                    .date {
                        padding-right: 3.5rem;
                        padding-bottom: 0;
                        border-right: 1px solid $white;
                    }
    
                    .defbtn {
                        margin-left: 3.5rem;
                    }
                }
            }
        }
    }
}