@charset "UTF-8";

@font-face {
  font-family: "fontello";
  src: url("../fonts/fontello.eot");
  src: url("../fonts/fontello.eot") format("embedded-opentype"), url("../fonts/fontello.woff2") format("woff2"), url("../fonts/fontello.woff") format("woff"), url("../fonts/fontello.ttf") format("truetype"), url("../fonts/fontello.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  font-size: 1.5em;
  padding-bottom: 0;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e800";
}

/* '' */

.icon-cancel:before {
  content: "\e804";
}

/* '' */

.icon-cancel-circle:before {
  content: "\e805";
}

/* '' */

.icon-mail:before {
  content: "\e808";
}

/* '' */

.icon-mail-1:before {
  content: "\e809";
}

/* '' */

.icon-globe:before {
  content: "\e80a";
}

/* '' */

.icon-map-marker:before {
  content: "\e80b";
}

/* '' */

.icon-envelope:before {
  content: "\e80c";
}

/* '' */

.icon-linkedin:before {
  content: "\e80d";
}

/* '' */

.icon-peace:before {
  content: "\e80e";
}

/* '' */

.icon-pager:before {
  content: "\e80f";
}

/* '' */

.icon-paper-plane:before {
  content: "\e810";
}

/* '' */

.icon-mask:before {
  content: "\e811";
}

/* '' */

.icon-mobile:before {
  content: "\e812";
}

/* '' */

.icon-mountain:before {
  content: "\e813";
}

/* '' */

.icon-angle-double-down:before {
  content: "\e814";
}

/* '' */

.icon-angle-right:before {
  content: "\e815";
}

/* '' */

.icon-search-1:before {
  content: "\e816";
}

/* '' */

.icon-download:before {
  content: "\f02e";
}

/* '' */

.icon-facebook:before {
  content: "\f230";
}

/* '' */

.white {
  color: #ffffff !important;
}

.blue {
  color: #93BCB7 !important;
}

.green {
  color: #3FA922 !important;
}

.black {
  color: #3A3A3A !important;
}

.white-bg {
  background-color: #ffffff !important;
}

.blue-bg {
  background-color: #93BCB7 !important;
}

.green-bg {
  background-color: #3FA922 !important;
}

.grey-bg {
  background-color: #939598 !important;
}

.black-bg {
  background-color: #3A3A3A !important;
}

html {
  box-sizing: border-box;
  font-size: 52.5%;
  scroll-behavior: smooth;
}

* {
  box-sizing: inherit;
}

*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.4;
  color: #3A3A3A;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s, background-color 0.2s;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

a:focus {
  outline: none;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

div {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: text;
}

.default-margin {
  margin: 4rem 0;
}

.default-padding {
  padding: 4rem 0;
}

header,
footer,
.hero-container,
.hero-wo-conf-container,
section.main-container {
  max-width: 1920px;
  margin: 0 auto;
}

section.main-container {
  background-color: #f1f1f1;
  overflow: auto;
}

.lazy {
  display: block;
}

@media (min-width: 1300px) {
  .default-margin {
    margin: 10rem 0;
  }

  .default-padding {
    padding: 10rem 0;
  }

  section.main-container,
  .thanks-404-container {
    min-height: calc(100vh - 43.4rem);
  }
}

p,
blockquote {
  font-weight: 400;
  font-size: 1.6rem;
}

h1 {
  font-weight: 300;
  font-size: 2.4rem;
}

h2 {
  font-weight: 900;
  font-size: 2.2rem;
}

h3 {
  font-weight: 700;
  font-size: 2rem;
}

p {
  line-height: 1.4em;
  margin-bottom: 1.4em;
}

ul {
  margin-bottom: 1.4em;
}

li {
  font-weight: 400;
  font-size: 1.6rem;
}

h1,
h2,
h3 {
  line-height: 1.2em;
  margin-bottom: 1.4em;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}

.black-900 {
  font-weight: 900 !important;
}

.uppercase {
  text-transform: uppercase;
}

blockquote {
  margin: 2em 2em 2em 0;
  border-left: 2px solid #3FA922;
  padding: 0 0 0 1em;
  font-style: italic;
  color: #939598;
}

.wider-heading {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.wider-heading h1 {
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1.5em;
}

.basic-heading {
  padding: 4rem 0 3rem;
}

.basic-heading h1 {
  font-size: 3.7rem;
  line-height: 4.5rem;
  font-weight: 900;
  color: #3FA922;
  text-transform: uppercase;
  margin-bottom: 0;
}

.basic-text-container {
  padding: 0 0 3rem;
}

.basic-text-container .editor-js-block {
  overflow-x: auto;
}

.basic-text-container p a {
  color: #3FA922;
}

.basic-text-container p a:hover {
  color: #3A3A3A;
}

.basic-text-container h1,
.basic-text-container h2,
.basic-text-container h3,
.basic-text-container h4 {
  color: #3FA922;
}

.basic-text-container h1,
.basic-text-container h2,
.basic-text-container h3 {
  margin-top: 1.4em;
  margin-bottom: 1em;
}

.basic-text-container h1:first-child,
.basic-text-container h2:first-child,
.basic-text-container h3:first-child {
  margin-top: 0;
}

.basic-text-container h1 {
  margin-bottom: 0.5rem;
}

.basic-text-container h2 {
  font-weight: 300;
}

.basic-text-container .bigger-margin-bottom {
  margin-bottom: 3rem;
}

.basic-text-container td {
  font-size: 1.4rem;
  padding: 1rem;
  background-color: rgba(147, 149, 152, 0.1);
  border: 2px solid #ffffff;
}

.basic-text-container img {
  max-width: 100%;
  height: auto !important;
}

.basic-text-container img,
.basic-text-container table {
  margin: 1em 0 2em;
}

@media (min-width: 1300px) {
  p,
  blockquote {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 4.9rem;
  }

  h2 {
    font-size: 3.6rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  li {
    font-size: 1.8rem;
  }

  blockquote {
    margin: 2em 2em 2em 1.5em;
    padding: 0 0 0 1.5em;
    border-left: 3px solid #3FA922;
  }

  .basic-heading {
    margin: 0 auto;
    padding: 6.5rem 0 4rem;
  }

  .basic-heading h1 {
    font-size: 7rem;
    line-height: 1em;
  }

  .basic-text-container h1 {
    margin-bottom: 0.5rem;
  }

  .basic-text-container .bigger-margin-bottom {
    margin-bottom: 5rem;
  }

  .basic-text-container td {
    font-size: 1.8rem;
    padding: 2rem;
  }
}

.container-fluid {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.container-fluid .row {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.container-fluid .row [class*=col-] {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.container-fluid .row .form-row {
  margin-right: -0.8rem !important;
  margin-left: -0.8rem !important;
}

.container-fluid .row .form-row > .col,
.container-fluid .row .form-row > [class*=col-] {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
}

@media (min-width: 1300px) {
  .container-fluid {
    max-width: 1300px;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .container-fluid .row {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .container-fluid .row [class*=col-] {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .container-fluid .row.no-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .container-fluid .row.no-gutters > .col,
  .container-fluid .row.no-gutters > [class*=col-] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .container-fluid.min-padding .row {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .container-fluid.min-padding .row [class*=col-] {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

button:focus {
  outline: none;
}

.defbtn {
  display: inline-block;
  position: relative;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1em;
  text-transform: uppercase;
  color: #ffffff;
  outline: none;
  padding: 1.7rem 3rem;
  border: none;
  text-align: center;
  background-color: #000000;
  border-radius: 1rem 0;
  transition: background-color 0.3s, color 0.3s, filter 0.3s;
  cursor: pointer;
  filter: brightness(1);
}

.defbtn:hover,
.defbtn:focus {
  filter: brightness(1.1);
  color: #ffffff;
}

.defbtn i {
  vertical-align: top;
  font-size: 1rem;
  margin-left: 1rem;
}

.defbtn.transparent {
  background-color: transparent;
  border: 1px solid #ffffff;
  font-size: 1.2rem;
  padding: 1.3rem 1.3rem 1.3rem 1.5rem;
  white-space: nowrap;
}

.defbtn.transparent i {
  vertical-align: top;
  font-size: 1rem;
  margin-left: 1rem;
}

.defbtn.transparent:hover,
.defbtn.transparent:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.defbtn.headerbtn {
  font-size: 1.3rem;
  padding: 1.5rem 1.3rem;
  white-space: nowrap;
}

.defbtn.headerbtn i {
  font-size: 0.8rem;
  vertical-align: top;
  margin-left: 1.5rem;
}

.defbtn.view-more {
  background-color: #3FA922;
  font-size: 1.4rem;
  padding: 2rem 2.6rem;
  border-radius: 1rem 0;
}

.defbtn.view-more i {
  font-size: 0.8rem;
  vertical-align: top;
  margin-left: 1.5rem;
}

.defbtn.lil {
  font-size: 1.6rem;
  padding: 1.7rem 3rem;
}

@media (min-width: 1300px) {
  .defbtn {
    font-size: 2.4rem;
    padding: 2.8rem 5rem;
    border-radius: 2rem 0;
  }

  .defbtn i {
    font-size: 1rem;
    margin-left: 1.4rem;
  }

  .defbtn.transparent {
    border: 2px solid #ffffff;
    border-radius: 1rem 0;
    font-size: 1.4rem;
    padding: 1.7rem 1.7rem 1.7rem 2.4rem;
  }

  .defbtn.transparent i {
    font-size: 1rem;
    margin-left: 1.4rem;
  }

  .defbtn.headerbtn {
    font-size: clamp(1.3rem, 1.3vw, 2.4rem);
    padding: 2.8rem 2.5rem;
  }

  .defbtn.headerbtn i {
    font-size: 1.2rem;
    margin-left: 4rem;
  }
}

footer {
  background-color: #ffffff;
  color: #3FA922;
  position: relative;
  padding: 3.5rem 0;
}

footer .footer-brand-logo {
  margin-bottom: 4rem;
}

footer a.footer-contact-item:hover,
footer .footer-policy a:hover,
footer a.footer-contact-item:focus,
footer .footer-policy a:focus {
  color: #3A3A3A;
}

footer .footer-contact,
footer .footer-policy {
  margin-bottom: 2.5rem;
}

footer .footer-policy a,
footer .footer-contact-item {
  font-size: 1.2rem;
  white-space: nowrap;
  display: block;
  margin-bottom: 1.2rem;
  min-height: 2.5rem;
}

footer .footer-contact-item i {
  margin-right: 1rem;
  display: inline-block;
  vertical-align: middle;
}

footer .footer-contact-item:last-child {
  margin-bottom: 0;
}

footer .footer-social {
  position: relative;
}

footer .footer-social .footer-simplepay {
  margin-bottom: 3.8rem;
}

footer .footer-social .social-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  line-height: 1em;
  color: #3FA922;
  background-color: #ffffff;
  outline: none;
  width: 5rem;
  height: 5rem;
  border: 1px solid #3FA922;
  border-radius: 0.7em 0;
  transition: background-color 0.3s, color 0.3s, filter 0.3s;
  cursor: pointer;
  margin: 0 1.3rem 0 0;
}

footer .footer-social .social-btn:hover,
footer .footer-social .social-btn:focus {
  color: #ffffff;
  background-color: #3FA922;
}

footer .footer-social .newsl-open {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  font-size: 1.5rem;
  line-height: 1em;
  color: #ffffff;
  background-color: #93BCB7;
  outline: none;
  border: none;
  border-radius: 0;
  transition: background-color 0.3s, color 0.3s, filter 0.3s;
  cursor: pointer;
}

footer .footer-social .newsl-box {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 7rem;
  left: 0;
  max-width: 40rem;
  background-color: #93BCB7;
  padding: 3rem;
  z-index: 3000;
  transform: translateX(-2rem);
  transition-property: opacity, transform, visibility;
  transition-duration: 0.3s;
}

footer .footer-social .newsl-box .newsl-close {
  position: absolute;
  top: 0.8rem;
  right: 1.5rem;
  color: #3A3A3A;
  font-size: 1.5rem;
}

footer .footer-social .newsl-box label {
  color: #ffffff;
}

footer .footer-social .newsl-box .form-group {
  margin-bottom: 3rem;
}

footer .footer-social .newsl-box .big-margin {
  margin: 3rem 0 2.5rem;
}

footer .footer-social .newsl-box .custom-control label {
  font-size: 1.2rem;
}

footer .footer-social .newsl-box.visible {
  visibility: visible;
  opacity: 1;
  transform: translateX(0rem);
}

@media (min-width: 1300px) {
  footer {
    padding: 6.6rem 0;
  }

  footer .footer-brand-logo {
    margin-bottom: 2.5rem;
  }

  footer .footer-contact-policy-flex {
    display: flex;
    justify-content: space-between;
  }

  footer .footer-contact,
  footer .footer-policy {
    margin-bottom: 0;
  }

  footer .footer-contact,
  footer .footer-policy,
  footer .footer-social {
    flex: 0 0 33.33333333%;
  }

  footer .footer-policy a,
  footer .footer-contact-item {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    min-height: 3rem;
  }

  footer .footer-policy {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  footer .footer-social .footer-simplepay {
    text-align: right;
    margin-bottom: 3rem;
  }

  footer .footer-social .social-box {
    text-align: right;
  }

  footer .footer-social .social-btn {
    font-size: 1.5rem;
    width: 5rem;
    height: 5rem;
    margin: 0 1.3rem 0 0;
  }

  footer .footer-social .newsl-open {
    width: 5rem;
    height: 5rem;
    font-size: 1.5rem;
  }

  footer .footer-social .newsl-box {
    right: 0;
    left: unset;
  }
}

form .form-control:focus,
form textarea:focus,
form select:focus,
form input[type]:focus,
form .uneditable-input:focus {
  box-shadow: 0 0 4px 2px rgba(58, 58, 58, 0.2);
  border: 1px solid rgba(58, 58, 58, 0.1);
  outline: 0 none;
}

form .form-group {
  margin-bottom: 1.5rem;
}

form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form textarea:-webkit-autofill,
form textarea:-webkit-autofill:hover,
form textarea:-webkit-autofill:focus,
form select:-webkit-autofill,
form select:-webkit-autofill:hover,
form select:-webkit-autofill:focus {
  border: 1px solid #ffffff;
  -webkit-text-fill-color: #3A3A3A;
  -webkit-box-shadow: 0 0 0px 1000px inherit inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 1.6rem;
}

form input[type],
form select,
form .form-control {
  font-size: 1.6rem;
  color: #3A3A3A;
  padding: 1.7rem 1.5rem;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0;
  height: auto;
}

form input[type]::placeholder,
form select::placeholder,
form .form-control::placeholder {
  color: #939598;
}

form input[type]:focus,
form select:focus,
form .form-control:focus {
  color: #3A3A3A;
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='18' height='18' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
}

form select option:disabled {
  color: #939598;
}

form label {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  color: #3A3A3A;
}

form .custom-checkbox {
  min-height: 3.4rem;
  padding-left: 0;
  margin: 0 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}

form .custom-control-label {
  font-size: 1.4rem;
  line-height: 1.2em;
  font-weight: 300;
  color: #3A3A3A;
  padding-left: 4.8rem;
  text-align: left;
  cursor: pointer;
  vertical-align: middle;
  text-transform: none;
  margin: 0;
}

form .custom-control-label a {
  text-decoration: underline;
}

form .custom-control-label::before {
  opacity: 0;
}

form .custom-control-label::before,
form .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 2.8rem;
  height: 2.8rem;
  background-color: #ffffff;
  border: none;
  border-radius: 0;
}

form .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../images/check_black.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

@media (min-width: 1300px) {
  form .form-group {
    margin-bottom: 3rem;
  }

  form input:-webkit-autofill,
  form input:-webkit-autofill:hover,
  form input:-webkit-autofill:focus,
  form textarea:-webkit-autofill,
  form textarea:-webkit-autofill:hover,
  form textarea:-webkit-autofill:focus,
  form select:-webkit-autofill,
  form select:-webkit-autofill:hover,
  form select:-webkit-autofill:focus {
    font-size: 1.8rem;
  }

  form input[type],
  form .form-control {
    font-size: 1.8rem;
  }

  form label {
    font-size: 2.4rem;
    margin-bottom: 0.5rem;
  }

  form .custom-control-label {
    font-size: 1.8rem;
    padding-left: 5.5rem;
  }

  form .custom-control-label::before,
  form .custom-control-label::after {
    width: 3.4rem;
    height: 3.4rem;
  }

  form .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-size: auto;
  }
}

header {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.menu-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  min-height: 7.8rem;
  transition: height 0.3s;
}

.menu-bar .search-lang {
  font-size: 1.4rem;
}

.menu-bar .search-lang .icon-globe {
  margin-right: 0.8rem;
}

.menu-bar .menu-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.menu-bar .menu-btn-label {
  font-size: 1.4rem;
  margin-right: 0.7rem;
  transition: color 0.5s ease;
}

.menu-bar .menu-icon {
  width: 2.4rem;
  margin-bottom: 0.3rem;
}

.menu-bar .menu-icon__line {
  height: 3px;
  width: 2.4rem;
  display: block;
  background-color: #3A3A3A;
  margin: 0.4rem 0;
  transition: transform 0.2s ease, background-color 0.5s ease;
}

.menu-bar .brand-logo {
  width: 12.5rem;
}

.menu-bar .brand-logo img {
  filter: invert(0.6);
  width: 100%;
  height: auto;
}

.menu-bar nav {
  padding: 0;
  z-index: 1000;
}

.menu-bar nav .nav-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 8rem;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  color: #ffffff;
  transition: visibility 0.6s, opacity 0.6s;
  transition-delay: 0;
  padding: 0 2rem;
}

.menu-bar nav .nav-content .nav-list {
  list-style: none;
  margin: 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  order: 0;
}

.menu-bar nav .nav-content .nav-list-item {
  display: block;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 1.5rem 0;
}

.menu-bar nav .nav-content .nav-list-item.active {
  color: #ffffff;
}

.menu-bar nav .nav-content .nav-contact-icons {
  display: flex;
  align-items: center;
  order: 2;
  margin-top: 1.5rem;
}

.menu-bar nav .nav-content .nav-contact-icons a {
  font-size: 1.5rem;
  margin: 0 3.5rem 0 0;
}

.menu-bar nav .nav-content .nav-contact {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  margin-top: 2.5rem;
  order: 1;
}

.menu-bar nav .nav-content .nav-contact-item {
  margin-bottom: 1.5rem;
}

.menu-bar nav .nav-content .nav-contact-item i {
  width: 4rem;
  display: inline-block;
}

.menu-bar nav .nav-content .nav-policy {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  order: 3;
}

.search-lang-dropdown {
  position: absolute;
  top: 7.8rem;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  height: 6.2rem;
  background-color: #ffffff;
  transition-property: opacity, transform, visibility;
  transition-duration: 0.3s;
}

.search-lang-dropdown a:hover {
  color: #3FA922;
}

.search-lang-dropdown.open {
  visibility: visible;
  opacity: 1;
}

.search-lang-dropdown .search-box,
.search-lang-dropdown .lang-box {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  padding: 0 1.5rem;
  visibility: hidden;
  opacity: 0;
  align-items: center;
  font-size: 1.6rem;
  transition-property: opacity, transform, visibility;
  transition-duration: 0.3s;
}

.search-lang-dropdown .search-box.open,
.search-lang-dropdown .lang-box.open {
  visibility: visible;
  opacity: 1;
}

.search-lang-dropdown .search-box {
  width: 100%;
}

.search-lang-dropdown .search-box form {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-lang-dropdown .search-box form input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #3A3A3A;
  margin: 0 1rem;
  padding: 1rem 1.5rem;
  flex: 1;
}

.search-lang-dropdown .search-box form input:focus {
  box-shadow: none;
  border-bottom: 1px solid #3A3A3A;
  outline: 0 none;
}

.search-lang-dropdown .lang-box {
  top: 0.6rem;
}

.search-lang-dropdown .lang-box .icon-globe {
  margin-right: 2rem;
}

.search-lang-dropdown .lang-box span {
  margin: 0 1rem;
}

.search-lang-dropdown .lang-box .lang-close {
  margin-left: 2rem;
}

.nav-bg:before,
.nav-bg:after {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(63, 169, 34, 0.5);
  z-index: 900;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s;
  transform: translateX(0%) translateY(-100%);
}

.nav-bg:after {
  background: rgba(63, 169, 34, 0.9);
  transition-delay: 0.1s;
}

.nav-bg:before {
  transition-delay: 0.2s;
}

.nav-active .menu-btn-label {
  color: #ffffff;
}

.nav-active .menu-icon__line {
  transform: translateX(0px) translateY(3px) rotate(-45deg);
  background-color: #ffffff;
}

.nav-active .menu-icon__line-bottom {
  transform: translateX(0px) translateY(-4px) rotate(45deg);
}

.nav-active .nav-bg:before,
.nav-active .nav-bg:after {
  transform: translateX(0%) translateY(0%);
}

.nav-active .nav-bg:after {
  transition-delay: 0.1s;
}

.nav-active .nav-bg:before {
  transition-delay: 0s;
}

.nav-active .menu-bar nav .nav-content {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
}

@media (min-width: 1300px) {
  .menu-bar {
    padding: 0 3.5rem;
    min-height: 9rem;
  }

  .menu-bar a:hover,
  .menu-bar a:focus {
    color: #3FA922;
  }

  .menu-bar .search-lang {
    font-size: clamp(1.2rem, 0.7vw, 1.5rem);
    position: relative;
  }

  .menu-bar .search-lang .icon-globe {
    margin-right: 1rem;
  }

  .menu-bar .brand-logo {
    width: auto;
  }

  .menu-bar .brand-logo img {
    width: 100%;
    height: auto;
  }

  .menu-bar nav {
    flex: 1;
    padding: 0 2.5rem;
  }

  .menu-bar nav .nav-content {
    position: static;
    visibility: visible;
    opacity: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #3A3A3A;
    transition: none;
    left: unset;
    top: unset;
    right: unset;
    padding: 0;
  }

  .menu-bar nav .nav-content .nav-list {
    display: block;
    border: none;
    padding: 0;
    order: unset;
  }

  .menu-bar nav .nav-content .nav-list-item {
    display: inline-block;
    margin: 0 1.29vw;
    font-size: clamp(1.4rem, 1vw, 1.9rem);
    order: unset;
  }

  .menu-bar nav .nav-content .nav-list-item.active,
  .menu-bar nav .nav-content .nav-list-item a:hover,
  .menu-bar nav .nav-content .nav-list-item a:focus {
    color: #3FA922;
  }

  .menu-bar nav .nav-content .nav-contact,
  .menu-bar nav .nav-content .nav-policy {
    display: none;
  }

  .menu-bar nav .nav-content .nav-contact-icons {
    margin-top: 0;
  }

  .menu-bar nav .nav-content .nav-contact-icons a {
    font-size: clamp(1rem, 0.6vw, 1.2rem);
    margin: 0 1.5rem;
  }

  .menu-bar nav .nav-content .nav-contact-icons .separator {
    height: 5.6rem;
    background-color: #3A3A3A;
    width: 0.1rem;
    margin-left: 1.5rem;
  }

  .search-lang-dropdown {
    top: 8rem;
  }

  .search-lang-dropdown .search-box,
  .search-lang-dropdown .lang-box {
    padding: 0 3.5rem;
  }

  .search-lang-dropdown .search-box {
    width: auto;
  }

  .search-lang-dropdown .search-box form {
    width: auto;
  }

  .search-lang-dropdown .search-box form input {
    width: 35rem;
    flex: unset;
  }
}

.hero-wo-conf-container {
  position: relative;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 7.8rem);
  padding-top: 6.9rem;
  overflow: hidden;
}

.hero-wo-conf-container h1 {
  font-size: 4.2rem;
  font-weight: 900;
  line-height: 1em;
  color: #3FA922;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.hero-wo-conf-container h2 {
  font-size: 2.1rem;
  font-weight: 500;
  line-height: 1.3em;
  color: #3A3A3A;
}

.hero-container {
  background-color: #f1f1f1;
}

.hero-container .hero-top {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: 39rem;
  padding: 4.5rem 0;
}

.hero-container .hero-top h1 {
  font-size: 3rem;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0.05em;
  line-height: 1.6em;
}

.hero-container .hero-top h1 span {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.15em 0.3em;
  box-decoration-break: clone;
}

.hero-container .hero-top h2 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.1em;
  color: #ffffff;
  margin-bottom: 0;
}

.hero-container .hero-top h2 .bg {
  background-color: rgba(63, 169, 34, 0.8);
  padding: 0.4em 0.6em;
  box-decoration-break: clone;
}

.hero-container .hero-top h2 .wp-now {
  white-space: nowrap;
}

.hero-container .hero-bottom {
  padding: 4.5rem 0;
}

.hero-container h3 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.9rem;
  margin-bottom: 2rem;
  color: #3A3A3A;
}

.hero-container .conf-cat,
.hero-container .conf-date {
  color: #3A3A3A;
}

.hero-container .date {
  color: #3A3A3A;
  font-weight: 300;
  display: flex;
  margin-bottom: 2rem;
}

.hero-container .date > div {
  width: 4.8rem;
  margin-right: 2rem;
}

.hero-container .date .num {
  font-size: 4rem;
  line-height: 5rem;
}

.hero-container .date .num-label {
  text-transform: uppercase;
  font-size: 0.8rem;
}

.hero-container .nums-container {
  color: #3A3A3A;
  font-weight: 300;
  display: flex;
  margin-bottom: 2rem;
}

.hero-container .nums-container .nums {
  width: 6.4rem;
  height: 6.1rem;
  border: 1px solid #3A3A3A;
  background-color: #ffffff;
  border-radius: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.2rem;
  margin-right: 1rem;
}

.hero-container .nums-container .nums .num {
  font-size: 2.7rem;
  line-height: 3.3rem;
}

.hero-container .nums-container .nums .num-label {
  font-weight: 500;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  font-size: 0.6rem;
}

.hero-container .nums_cta .cta {
  display: inline-flex;
  flex-direction: column;
}

.hero-container .nums_cta .reg {
  padding: 1.5rem 1.3rem;
  border-bottom-right-radius: 0;
}

.hero-container .nums_cta .program {
  background-color: #ffffff;
  border: 1px solid #3A3A3A;
  font-size: 1rem;
  padding: 1.5rem 1rem;
  border-top-left-radius: 0;
  white-space: nowrap;
  color: #3A3A3A;
}

.hero-container .nums_cta .program .icon-angle-right {
  vertical-align: top;
  font-size: 1rem;
  margin-left: 1rem;
}

.hero-container .nums_cta .program:hover,
.hero-container .nums_cta .program:focus {
  background-color: #f1f1f1;
  filter: brightness(1);
}

.scroll-down {
  position: absolute;
  max-width: 1300px;
  width: 100%;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  padding-left: 3rem;
  padding-right: 3rem;
  color: #ffffff;
}

.scroll-down h4 {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.scroll-down i {
  display: block;
  font-size: 1rem;
  animation: sdb04 2s infinite;
  transform: translateY(0);
  position: relative;
}

@keyframes sdb04 {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(10px);
  }

  40% {
    transform: translateY(0);
  }
}

@media (min-width: 1300px) {
  .hero-wo-conf-container {
    padding-top: clamp(7rem, 7.3vw, 14rem);
    height: calc(100vh - 9rem);
  }

  .hero-wo-conf-container h1 {
    font-size: clamp(6rem, 5.3vw, 10rem);
    margin-bottom: 0.2em;
    max-width: 70%;
  }

  .hero-wo-conf-container h2 {
    font-size: clamp(3rem, 2.6vw, 4.9rem);
    max-width: 55%;
  }

  .hero-container {
    height: calc(100vh - 9rem);
    overflow: hidden;
  }

  .hero-container .hero-top {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .hero-container .hero-top h1 {
    font-size: clamp(5rem, 3.9vw, 7.5rem);
  }

  .hero-container .hero-top h2 {
    font-size: clamp(2rem, 2vw, 3.8rem);
    max-width: 70%;
  }

  .hero-container .hero-bottom {
    height: 50%;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .hero-container h3 {
    font-size: clamp(1.6rem, 1.3vw, 2.4rem);
    line-height: 1.2em;
    margin-bottom: clamp(2rem, 1.6vw, 3rem);
  }

  .hero-container .date_cta,
  .hero-container .nums_cta {
    display: flex;
    align-items: center;
  }

  .hero-container .date {
    color: #ffffff;
    margin-right: 15rem;
    margin-bottom: 0;
  }

  .hero-container .date > div {
    width: 8rem;
    margin-right: 3rem;
  }

  .hero-container .date .num {
    font-size: 6rem;
    line-height: 7rem;
  }

  .hero-container .date .num-label {
    font-size: 1.2rem;
  }

  .hero-container .nums-container {
    margin-right: 9rem;
    margin-bottom: 0;
  }

  .hero-container .nums-container .nums {
    width: clamp(6.4rem, 8.2vw, 15.6rem);
    height: clamp(6.1rem, 7.9vw, 15rem);
    border: 2px solid #3A3A3A;
    border-radius: 2rem 0;
    padding: 0 clamp(1.2rem, 1.6vw, 3rem);
    margin-right: 3rem;
  }

  .hero-container .nums-container .nums .num {
    font-size: clamp(4rem, 3.4vw, 6.6rem);
    line-height: 1.2em;
  }

  .hero-container .nums-container .nums .num-label {
    font-size: clamp(0.4rem, 0.65vw, 1.3rem);
  }

  .hero-container .nums_cta .reg {
    padding: clamp(1.3rem, 1.3vw, 2.5rem);
  }

  .hero-container .nums_cta .program {
    font-size: clamp(1rem, 1.1vw, 2rem);
    padding: clamp(1.3rem, 1.3vw, 2.5rem);
  }

  .hero-container .nums_cta .program .icon-angle-right {
    font-size: 1rem;
    margin-left: 1rem;
  }

  .header-contact-container {
    bottom: 2rem;
    right: 3.5rem;
    font-size: 1.3rem;
  }

  .header-contact-container a {
    margin: 1.5rem 0;
  }

  .scroll-down {
    bottom: 2rem;
  }

  .scroll-down h4 {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .scroll-down i {
    font-size: 1.4rem;
  }
}

@media (min-width: 1500px) {
  .header-contact-container {
    left: 3.5rem;
    right: unset;
  }
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #3FA922;
  cursor: pointer;
  opacity: 1;
}

.flickity-page-dots .dot.is-selected {
  background: #3FA922;
}

.flickity-button {
  position: absolute;
  background: none;
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: none;
  cursor: pointer;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  fill: #3FA922;
}

.flickity-prev-next-button.previous {
  left: -2rem;
}

.flickity-prev-next-button.next {
  right: -2rem;
}

@media (min-width: 1300px) {
  .flickity-prev-next-button.previous {
    left: -3rem;
  }

  .flickity-prev-next-button.next {
    right: -3rem;
  }
}

.about-container {
  position: relative;
  padding: 5.5rem 0 1rem;
  background-color: #93BCB7;
  color: #ffffff;
  overflow: hidden;
}

.about-container .about-bg-text {
  position: absolute;
  font-size: 11.4rem;
  font-weight: 900;
  top: 1rem;
  left: -2rem;
  opacity: 0.3;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1em;
}

.about-container h1 {
  margin-bottom: 5.5rem;
  text-transform: uppercase;
}

.about-container p {
  margin-bottom: 3rem;
}

.about-container .about-block {
  margin-bottom: 3rem;
}

.about-container .about-block .about-text,
.about-container .about-block .about-images {
  align-self: center;
}

.about-container .about-block:nth-of-type(2) .about-text {
  order: 1;
}

.about-container .about-block:nth-of-type(2) .about-images {
  order: 2;
}

.about-container img {
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
  max-width: calc(100% + 3rem);
  height: auto;
}

@media (min-width: 1300px) {
  .about-container {
    padding: 16rem 0 0;
  }

  .about-container .about-bg-text {
    font-size: 30rem;
    top: 3rem;
    left: -10rem;
  }

  .about-container h1 {
    margin-bottom: 9rem;
  }

  .about-container h3 {
    margin-bottom: 6rem;
  }

  .about-container .about-block {
    margin-bottom: 7rem;
  }

  .about-container .about-block:nth-of-type(2) .about-text {
    order: 2;
  }

  .about-container .about-block:nth-of-type(2) .about-images {
    order: 1;
  }

  .about-container img {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}

.com-prev-container {
  padding: 3rem 0 6rem;
}

.com-prev-container .cu-container {
  margin-bottom: 5rem;
}

.com-prev-container .cu-heading h1,
.com-prev-container .pre-heading h1 {
  margin: 0 -1.5rem 0.5rem -1.5rem;
  text-transform: uppercase;
}

.com-prev-container .cu-heading span,
.com-prev-container .pre-heading span {
  font-size: 1.5em;
  font-weight: 900;
  color: #93BCB7;
}

.com-prev-container .cu-heading h3,
.com-prev-container .pre-heading h3 {
  font-weight: 700;
  margin-left: 0;
  margin-bottom: 3rem;
}

.com-prev-container .pre-heading span {
  color: #3FA922;
}

.com-prev-container .cu-conf-container {
  position: relative;
  margin-bottom: 2rem;
}

.com-prev-container .cu-conf-container .conf-img {
  width: calc(100% + 3rem);
  height: 24.2rem;
  margin: 0 -1.5rem;
  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.com-prev-container .cu-conf-container .conf-content {
  width: 100%;
  margin: -7.6rem 0 0 0;
  background-color: #93BCB7;
  padding: 2.5rem 1.5rem;
  color: #ffffff;
}

.com-prev-container .cu-conf-container .conf-content h2 {
  margin-bottom: 1.7rem;
}

.com-prev-container .cu-conf-container .conf-content p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.com-prev-container .cu-conf-container .conf-content .offer {
  margin: 2rem 0 0;
}

.com-prev-container .cu-conf-container .conf-content .conf-info {
  margin-bottom: 2.3rem;
}

.com-prev-container .prev-conf-container {
  display: block;
  margin: 0 -1.5rem;
}

.com-prev-container .prev-conf-container .prev-conf-box {
  margin-bottom: 2rem;
  width: 100%;
  margin-right: 1.5rem;
}

.com-prev-container .prev-conf-container .prev-conf-img {
  height: 17rem;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.com-prev-container .prev-conf-container .prev-conf-content {
  background-color: #3FA922;
  color: #ffffff;
  padding: 2.5rem 2rem;
}

.com-prev-container .prev-conf-container .prev-conf-content h3 {
  font-weight: 500;
  text-transform: uppercase;
}

.com-prev-container .prev-conf-container .prev-conf-content p {
  font-size: 1.2rem;
  font-weight: 900;
  margin-bottom: 0;
}

.com-prev-container .prev-conf-container .prev-conf-content .detail .date {
  padding-bottom: 1rem;
}

@media (min-width: 1300px) {
  .com-prev-container {
    padding: 12rem 0 15rem;
  }

  .com-prev-container .cu-container {
    margin-bottom: 10rem;
  }

  .com-prev-container .cu-heading h1,
  .com-prev-container .pre-heading h1 {
    margin: 0 0 1.5rem;
  }

  .com-prev-container .cu-heading span,
  .com-prev-container .pre-heading span {
    font-size: 1.9em;
  }

  .com-prev-container .cu-heading h3,
  .com-prev-container .pre-heading h3 {
    margin-bottom: 2.5em;
  }

  .com-prev-container .cu-conf-container {
    margin-bottom: 10rem;
  }

  .com-prev-container .cu-conf-container.left {
    margin-bottom: 2rem;
  }

  .com-prev-container .cu-conf-container.left .conf-img {
    position: relative;
    margin: 6rem 0 0 auto;
  }

  .com-prev-container .cu-conf-container.left .conf-content {
    left: 0;
    top: -4rem;
    right: unset;
    bottom: unset;
    background-color: #93BCB7;
    padding: 5rem 6.5rem 4.4rem;
    color: #ffffff;
  }

  .com-prev-container .cu-conf-container .conf-img {
    width: 50%;
    height: 42rem;
    margin: 0;
  }

  .com-prev-container .cu-conf-container .conf-content {
    position: absolute;
    bottom: -4rem;
    right: 0;
    width: 76rem;
    margin: 0;
    padding: 5rem 6.5rem 4.4rem;
  }

  .com-prev-container .cu-conf-container .conf-content h2 {
    margin-bottom: 3.5rem;
    max-width: 50rem;
  }

  .com-prev-container .cu-conf-container .conf-content p {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 0;
  }

  .com-prev-container .cu-conf-container .conf-content .offer {
    margin: 0;
  }

  .com-prev-container .cu-conf-container .conf-content .conf-info {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
  }

  .com-prev-container .cu-conf-container .conf-content .conf-info .date {
    padding-right: 4rem;
    border-right: 2px solid #ffffff;
  }

  .com-prev-container .cu-conf-container .conf-content .conf-info .desc {
    padding-left: 4rem;
    max-width: 35rem;
  }

  .com-prev-container .cu-conf-container .conf-content .detail {
    display: flex;
    align-items: center;
  }

  .com-prev-container .cu-conf-container .conf-content .detail .defbtn {
    margin-right: 4rem;
  }

  .com-prev-container .prev-conf-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin: 0;
  }

  .com-prev-container .prev-conf-container .prev-conf-box {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .com-prev-container .prev-conf-container .prev-conf-img {
    height: 32rem;
  }

  .com-prev-container .prev-conf-container .prev-conf-content {
    padding: 4rem 6.7rem;
    flex: 1;
  }

  .com-prev-container .prev-conf-container .prev-conf-content p {
    font-size: 1.6rem;
  }

  .com-prev-container .prev-conf-container .prev-conf-content .detail {
    display: flex;
    align-items: center;
  }

  .com-prev-container .prev-conf-container .prev-conf-content .detail .date {
    padding-right: 3.5rem;
    padding-bottom: 0;
    border-right: 1px solid #ffffff;
  }

  .com-prev-container .prev-conf-container .prev-conf-content .detail .defbtn {
    margin-left: 3.5rem;
  }
}

.reg-cta-container {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #3FA922;
  padding: 6rem 0;
}

.reg-cta-container .reg-cta-wraper {
  position: relative;
  max-width: 95rem;
  width: 100%;
  padding: 0 3rem;
}

.reg-cta-container .reg-cta-wraper .reg-cta-content {
  position: relative;
  z-index: 1;
  padding: 5rem 5rem 3rem;
  width: 100%;
  margin: -7.6rem 0 0 0;
  background-color: #ffffff;
  color: #ffffff;
}

.reg-cta-container .reg-cta-wraper .reg-cta-content h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
  color: #3FA922;
  text-transform: uppercase;
}

.reg-cta-container .reg-cta-wraper .reg-cta-content p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 300;
  margin-bottom: 2.5rem;
  color: #3A3A3A;
}

.reg-cta-container .reg-cta-wraper .reg-cta-img {
  position: relative;
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
  height: 18.2rem;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 1300px) {
  .reg-cta-container {
    padding: 11rem 0;
  }

  .reg-cta-container .reg-cta-wraper {
    position: relative;
    width: 100%;
    padding: 0;
  }

  .reg-cta-container .reg-cta-wraper .reg-cta-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 9rem 7rem 4rem;
    margin: 0;
    width: 45.2rem;
  }

  .reg-cta-container .reg-cta-wraper .reg-cta-content h2 {
    font-size: 3.6rem;
    line-height: 4.4rem;
    margin-bottom: 3.5rem;
  }

  .reg-cta-container .reg-cta-wraper .reg-cta-content p {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 3.5rem;
  }

  .reg-cta-container .reg-cta-wraper .reg-cta-img {
    margin: 0 0 0 auto;
    width: 60.8rem;
    height: 42rem;
  }
}

.conferences-header {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 6rem 0 0;
}

.conferences-header .ch-container {
  position: relative;
  max-width: 1630px;
  width: 100%;
  padding: 0 3rem;
}

.conferences-header .ch-container .ch-content {
  position: relative;
  z-index: 1;
  padding: 3rem 2rem;
  width: 100%;
  margin: -7.6rem 0 0 0;
  background-color: #3FA922;
  color: #ffffff;
}

.conferences-header .ch-container .ch-content h1 {
  font-size: 3.6rem;
  line-height: 3.6rem;
  font-weight: 900;
  margin-bottom: 1.8rem;
  text-transform: uppercase;
}

.conferences-header .ch-container .ch-content p {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 1.5rem;
}

.conferences-header .ch-container .ch-content .conf-info {
  margin-bottom: 2.3rem;
}

.conferences-header .ch-container .ch-content .conf-info .date {
  font-weight: 600;
}

.conferences-header .ch-container .ch-content .conf-info .desc {
  font-weight: 400;
}

.conferences-header .ch-container .ch-img {
  position: relative;
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
  height: 28rem;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 1300px) {
  .conferences-header {
    margin: 4rem 0 0;
  }

  .conferences-header .ch-container .ch-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 6rem 16rem 6.5rem;
    margin: 0;
    width: 107rem;
  }

  .conferences-header .ch-container .ch-content h1 {
    font-size: 10rem;
    line-height: 10rem;
    margin-bottom: 5rem;
  }

  .conferences-header .ch-container .ch-content p {
    font-size: 2.4rem;
    line-height: 2.9rem;
    margin-bottom: 0;
  }

  .conferences-header .ch-container .ch-content .conf-info {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
  }

  .conferences-header .ch-container .ch-content .conf-info .date {
    padding-right: 4rem;
    border-right: 2px solid #ffffff;
  }

  .conferences-header .ch-container .ch-content .conf-info .desc {
    padding-left: 4rem;
    max-width: 47rem;
  }

  .conferences-header .ch-container .ch-img {
    margin: 0 0 0 auto;
    width: 107rem;
    height: 76.2rem;
  }
}

.contact-form-container {
  padding: 7rem 0 4rem;
  background-color: #3FA922;
  margin-top: 19rem;
}

.contact-form-container .chameleon {
  position: absolute;
  top: -23.5rem;
  left: -6rem;
  width: 42rem;
  pointer-events: none;
}

.contact-form-container .chameleon img {
  width: 100%;
  height: auto;
}

.contact-form-container h2 {
  color: #ffffff;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.contact-form-container h2 span {
  font-size: 5rem;
  font-weight: 900;
}

.contact-form-container h3 {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
  max-width: 29.5rem;
  margin-bottom: 3.5rem;
}

.contact-form-container form .form-group {
  margin-bottom: 3rem;
}

.contact-form-container form label {
  color: #ffffff;
}

.contact-form-container form .custom-control-label {
  font-size: 1.4rem;
}

@media (min-width: 1300px) {
  .contact-form-container {
    padding: 9.5rem 0 8.8rem;
  }

  .contact-form-container .chameleon {
    top: -42.9rem;
    left: -11.5rem;
    width: 84.8rem;
  }

  .contact-form-container h2 {
    font-size: 3.6rem;
    margin-bottom: 1.5rem;
  }

  .contact-form-container h2 span {
    font-size: 7rem;
  }

  .contact-form-container h3 {
    font-size: 2rem;
    line-height: 2.4rem;
    max-width: 35rem;
    margin-bottom: 0;
  }

  .contact-form-container form .form-group {
    margin-bottom: 3rem;
  }

  .contact-form-container form .custom-control-label {
    font-size: 1.8rem;
  }
}

.documents-container {
  position: relative;
  margin: 4rem 0;
}

.documents-container .document-link {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.documents-container .document-link i {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #3FA922;
}

.documents-container .document-link a {
  display: flex;
}

.documents-container .document-link a:hover,
.documents-container .document-link a:focus {
  color: #3FA922;
}

@media (min-width: 1300px) {
  .documents-container {
    margin: 10rem 0;
  }

  .documents-container .document-link {
    font-size: 2.4rem;
    line-height: 2.9rem;
    margin-bottom: 0;
  }

  .documents-container .document-link i {
    font-size: 1.8rem;
    margin-right: 1.5rem;
  }

  .documents-container .documents-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
  }
}

.program-container {
  position: relative;
  margin: 4rem 0;
}

.program-container h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.program-container h4 {
  margin-bottom: 1rem;
}

.program-container h4,
.program-container p {
  font-size: 1.2rem;
}

.program-container .program-box {
  display: flex;
  margin: 0 -1.5rem 1.5rem;
  width: calc(100% + 3rem);
}

.program-container .program-img {
  width: 6rem;
  height: 11rem;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.program-container .program-data {
  flex: 1;
  padding: 1.5rem 1.5rem;
  background-color: #93BCB7;
  color: #ffffff;
}

.program-container .program-data .less {
  display: none;
}

.program-container .program-desc {
  padding-left: 1.5rem;
  border-left: 2px solid #ffffff;
}

.program-container .more-desc-btn {
  text-transform: uppercase;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.4rem;
}

.program-container .more-desc-btn i {
  display: inline-block;
  margin-right: 1rem;
}

.program-container .more-desc-btn.back i {
  transform: rotate(180deg);
}

.program-container .more-desc {
  display: none;
  margin-top: 2rem;
}

@media (min-width: 1300px) {
  .program-container {
    margin: 10rem 0;
  }

  .program-container h3 {
    margin-bottom: 1rem;
  }

  .program-container h4 {
    margin-bottom: 3rem;
  }

  .program-container h4,
  .program-container p {
    font-size: 1.6rem;
  }

  .program-container .program-box {
    margin: 0 0 2rem;
    width: 100%;
  }

  .program-container .program-img {
    width: 29.6rem;
    height: 27rem;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .program-container .program-data {
    padding: 4rem 6rem 3rem;
  }

  .program-container .program-desc {
    padding-left: 3rem;
    border-left: 2px solid #ffffff;
  }

  .program-container .more-desc-btn {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .program-container .more-desc-btn i {
    margin-right: 1.5rem;
  }

  .program-container .more-desc {
    margin-top: 3rem;
  }
}

.reg-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.reg-container h2 {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.reg-container .reg-sale-end {
  background-color: #3FA922;
  padding: 3rem 2rem 2.5rem;
  margin: 0 0 4rem;
  color: #ffffff;
  border-radius: 2rem 0;
}

.reg-container .reg-sale-end .date {
  color: #ffffff;
  font-weight: 300;
  display: flex;
}

.reg-container .reg-sale-end .date > div {
  margin-right: 2rem;
}

.reg-container .reg-sale-end .date .num {
  font-size: 4rem;
  line-height: 5rem;
}

.reg-container .reg-sale-end .date .num-label {
  text-transform: uppercase;
  font-size: 0.8rem;
}

.reg-container .reg-form {
  background-color: #DEF1D9;
  padding: 3rem 3rem 3.5rem;
  margin: 0 -3rem 3rem;
}

.reg-container .reg-form .form-label,
.reg-container .reg-form .person-hl {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.reg-container .reg-form .mp-top {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
}

.reg-container .reg-form .remove-clone {
  font-size: 1.4rem;
  line-height: 1.3em;
  margin-left: 2rem;
}

.reg-container .reg-form .remove-clone:hover {
  color: #3FA922;
}

.reg-container .reg-conf-rule {
  background-color: rgba(147, 188, 183, 0.25);
  padding: 4rem 2rem 3.5rem;
  margin: 0;
}

.reg-container .reg-conf-rule .rules {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.reg-container .reg-conf-rule .rules i {
  display: block;
  font-size: 2rem;
  flex: 0 0 5rem;
  text-align: center;
  margin-right: 1rem;
}

.reg-container .reg-conf-rule .rules .rule-desc p:last-child {
  margin-bottom: 0;
}

.reg-container form .ticket-selector .ticket-btn {
  width: 100%;
  height: 5rem;
  position: relative;
}

.reg-container form .ticket-selector .ticket-btn label {
  color: #ffffff;
}

.reg-container form .ticket-selector label,
.reg-container form .ticket-selector input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.reg-container form .ticket-selector input[type=radio] {
  opacity: 0.011;
  z-index: 100;
}

.reg-container form .ticket-selector input[type=radio]:checked + label {
  background: #3FA922;
}

.reg-container form .ticket-selector label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 90;
  padding: 1rem;
  text-align: center;
  color: #ffffff;
  font-size: 1.4rem;
  background-color: rgba(147, 149, 152, 0.5);
  border-radius: 1rem 0;
  transition: background-color 0.3s, color 0.3s, filter 0.3s;
  filter: brightness(1);
  margin-bottom: 0;
}

.reg-container form .ticket-selector label:hover {
  filter: brightness(1.1);
}

.reg-container form input[type],
.reg-container form .form-control,
.reg-container form .custom-control-label::before,
.reg-container form .custom-control-label::after {
  border: 1px solid #707070;
}

.reg-container form input:-webkit-autofill,
.reg-container form input:-webkit-autofill:hover,
.reg-container form input:-webkit-autofill:focus,
.reg-container form textarea:-webkit-autofill,
.reg-container form textarea:-webkit-autofill:hover,
.reg-container form textarea:-webkit-autofill:focus,
.reg-container form select:-webkit-autofill,
.reg-container form select:-webkit-autofill:hover,
.reg-container form select:-webkit-autofill:focus {
  border: 1px solid #707070;
}

.reg-container form .select-width {
  width: 100%;
}

.reg-container form hr {
  margin-top: 0;
  border: 0;
  border-top: 2px solid #3FA922;
}

.reg-container .subtotal {
  color: #3A3A3A;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.reg-container .subtotal .subtotal-num {
  text-align: right;
}

.reg-container .subtotal .subtotal-num span {
  font-weight: 700;
}

.reg-container .total {
  padding: 2.3rem 2.5rem;
  background-color: #3FA922;
  color: #ffffff;
  font-size: 3rem;
  text-align: right;
}

.reg-container .simplepay-img {
  margin: 4rem 0;
}

.price-row {
  font-size: 1.6rem;
}

.payment-container {
  margin: 0 0 3rem;
}

@media (min-width: 1300px) {
  .reg-container {
    margin: 0 0 7rem;
    padding: 0;
  }

  .reg-container::after {
    content: "";
    clear: both;
    display: table;
  }

  .reg-container .reg-sale-end {
    padding: 5.5rem 4rem 7rem;
    float: right;
    max-width: 43.5%;
    width: 100%;
    margin: 0 0 1.5rem;
  }

  .reg-container .reg-sale-end .date > div {
    margin-right: 3rem;
  }

  .reg-container .reg-sale-end .date .num {
    font-size: 6rem;
    line-height: 7rem;
  }

  .reg-container .reg-sale-end .date .num-label {
    font-size: 1.2rem;
  }

  .reg-container .ticket-sale {
    float: left;
    max-width: 55%;
  }

  .reg-container .reg-form {
    padding: 3.5rem 4.5rem 4rem;
    width: 100%;
    margin: 0 0 1.5rem;
  }

  .reg-container .reg-form .form-label,
  .reg-container .reg-form .person-hl {
    font-size: 3rem;
    margin-bottom: 4.5rem;
  }

  .reg-container .reg-form .remove-clone {
    font-size: 2rem;
  }

  .reg-container .reg-conf-rule {
    padding: 4rem 4rem;
    float: right;
    max-width: 43.5%;
    width: 100%;
    margin: 0;
  }

  .reg-container .reg-conf-rule .rules {
    margin-bottom: 2rem;
  }

  .reg-container .reg-conf-rule .rules i {
    font-size: 2.4rem;
    flex: 0 0 6rem;
    margin-right: 1.5rem;
  }

  .reg-container form .ticket-selector .ticket-btn {
    width: 100%;
    height: 8rem;
  }

  .reg-container form .ticket-selector label {
    font-size: 2.4rem;
    border-radius: 2rem 0;
  }

  .reg-container form .select-width {
    width: 60%;
  }

  .reg-container form .subtotal {
    font-size: 2.3rem;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .reg-container form .total {
    padding: 2.3rem 2.5rem;
    font-size: 3rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .reg-container .simplepay-img {
    margin: 5rem 0 6rem;
  }

  .price-row {
    font-size: 1.8rem;
  }

  .payment-container {
    margin: 0 0 7rem;
  }
}

.sponsors-container {
  position: relative;
  margin: 4rem 0;
  overflow: hidden;
}

.sponsors-container .sponsors-slider {
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
}

.sponsors-container .sponsor-img {
  max-width: calc(50% - 5rem);
  width: 100%;
  height: 9rem;
  margin: 0 2.5rem;
}

.sponsors-container .sponsor-img img {
  display: block;
  max-width: 100%;
  height: 9rem;
  margin: 0 auto;
  object-fit: contain;
}

@media (min-width: 1300px) {
  .sponsors-container {
    margin: 10rem 0;
  }

  .sponsors-container .sponsors-slider {
    margin: 0;
    width: 100%;
  }

  .sponsors-container .sponsor-img {
    max-width: calc(20% - 5rem);
    height: 22rem;
  }

  .sponsors-container .sponsor-img img {
    height: 22rem;
  }
}

.search-container {
  margin-bottom: 4rem;
}

.search-padding-top {
  padding-top: 6rem;
}

@media (min-width: 1300px) {
  .search-container {
    margin-bottom: 6rem;
  }

  .search-padding-top {
    padding-top: 8rem;
  }
}

body.popup-open {
  overflow: hidden;
  padding-right: 17px;
}

.team-container {
  position: relative;
  margin: 4rem 0 6rem;
}

.team-container .team-slider {
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
}

.team-container .team-member {
  width: calc(50% - 0.5rem);
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.team-container .team-popup {
  visibility: hidden;
  cursor: pointer;
}

.team-container .team-popup.open {
  visibility: visible;
}

.team-container .team-popup.open .popup .popup-dialog {
  transform: none;
}

.team-container .team-popup.open .popup-backdrop {
  opacity: 0.4;
}

.team-container .team-popup .popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
}

.team-container .team-popup .popup .popup-dialog {
  max-width: 100rem;
  width: 100%;
  padding: 4rem 2rem 2rem;
  background-color: rgba(222, 241, 217, 0.95);
  transform: scale(0.9);
  transition: transform 0.2s ease-out;
  position: relative;
}

.team-container .team-popup .popup .popup-dialog .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: auto;
}

.team-container .team-popup .popup .popup-image {
  width: 100%;
  height: 19.3rem;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 19.3rem;
  margin-bottom: 2rem;
}

.team-container .team-popup .popup h4 {
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
  font-weight: 500;
  color: #3A3A3A;
  text-transform: uppercase;
}

.team-container .team-popup .popup p {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0;
}

.team-container .team-popup .popup hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 0;
  border: 0;
  border-top: 5px solid #3FA922;
  width: 9rem;
}

.team-container .team-popup .popup .job-box {
  display: flex;
  width: 100%;
}

.team-container .team-popup .popup .job-box p {
  flex: 1;
  margin-right: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #3A3A3A;
  margin-bottom: 0;
}

.team-container .team-popup .popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0;
  transition: all 0.2s;
}

.team-container .member-image {
  width: 100%;
  height: 19.3rem;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 19.3rem;
}

.team-container .member-detail {
  position: relative;
  background-color: rgba(63, 169, 34, 0.17);
  padding: 1.3rem 4rem 1.3rem 1rem;
}

.team-container .member-detail h4 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #3A3A3A;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
}

.team-container .member-detail p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #3A3A3A;
  margin-bottom: 0;
}

.team-container .member-detail .member-linkedin {
  position: absolute;
  bottom: 0.2rem;
  right: 0.7rem;
  font-size: 1.2rem;
}

.team-container .member-hl {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #93BCB7;
}

.team-container.no-slide .team-slider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 2rem;
}

.team-container.no-slide .team-member {
  width: 100%;
  margin-right: 0;
}

@media (min-width: 1300px) {
  .team-container {
    margin: 10rem 0;
  }

  .team-container .team-slider,
  .team-container.no-slide .team-slider {
    /*display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1.6rem;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    width: 100%;
    gap: 2rem;
  }

  .team-container .team-member {
    width: 100%;
    flex: 0 0 calc(25% - 1.5rem);
    margin: 0;
  }

  .team-container .team-popup .popup {
    padding: 0;
  }

  .team-container .team-popup .popup .popup-dialog {
    padding: 7rem;
  }

  .team-container .team-popup .popup .popup-dialog .close {
    top: 2rem;
    right: 2rem;
    width: 2.8rem;
  }

  .team-container .team-popup .popup .popup-content {
    display: flex;
  }

  .team-container .team-popup .popup .popup-image {
    flex: 0 0 29rem;
    width: 100%;
    height: 34rem;
    background-color: #ffffff;
    background-size: cover;
    margin-right: 7rem;
    margin-bottom: 0;
  }

  .team-container .team-popup .popup h4 {
    font-size: 1.8rem;
  }

  .team-container .team-popup .popup p {
    font-size: 1.4rem;
  }

  .team-container .team-popup .popup hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .team-container .team-popup .popup .job-box {
    width: 26rem;
  }

  .team-container .team-popup .popup .job-box p {
    font-size: 1.2rem;
  }

  .team-container .team-popup .popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    transition: all 0.2s;
  }

  .team-container .member-image {
    height: 32.7rem;
    background-color: #ffffff;
    background-size: cover;
  }

  .team-container .member-detail {
    padding: 1.5rem 4rem 1.5rem 2.3rem;
    flex: 1;
  }

  .team-container .member-detail h4 {
    font-size: 1.8rem;
    margin-bottom: 0.4rem;
  }

  .team-container .member-detail p {
    font-size: 1.2rem;
  }

  .team-container .member-detail .member-linkedin {
    bottom: 0.5rem;
    right: 1rem;
    font-size: 1.6rem;
  }

  .team-container .member-hl {
    padding: 1rem 2.3rem;
    font-size: 1.6rem;
  }
}

.thanks-404-container {
  position: relative;
  background-color: #3FA922;
  padding: 4rem 0 3rem;
  color: #ffffff;
}

.thanks-404-container h1 {
  text-transform: uppercase;
}

.thanks-404-container .big-hl {
  font-size: 6rem;
  line-height: 1em;
  font-weight: 900;
  margin-bottom: 0;
}

.thanks-404-container p {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 500;
}

@media (min-width: 1300px) {
  .thanks-404-container {
    padding: 12rem 0 9rem;
  }

  .thanks-404-container .big-hl {
    font-size: 12rem;
  }

  .thanks-404-container p {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
}

.videos-container {
  position: relative;
  margin: 4rem 0;
  overflow: hidden;
}

.videos-container .flickity-prev-next-button.previous {
  left: -0.3rem;
}

.videos-container .flickity-prev-next-button.next {
  right: -0.3rem;
}

.videos-container .video-box {
  width: 85%;
  margin-right: 0.6rem;
  opacity: 0.3;
  transition: opacity 0.3s;
  pointer-events: none;
}

.videos-container .video-box.is-selected {
  opacity: 1;
  pointer-events: auto;
}

.videos-container .video-responsive {
  padding-bottom: 56.25%;
  position: relative;
}

.videos-container .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media (min-width: 1300px) {
  .videos-container {
    margin: 10rem 0;
  }

  .videos-container .flickity-prev-next-button.previous {
    left: 3rem;
  }

  .videos-container .flickity-prev-next-button.next {
    right: 3rem;
  }

  .videos-container .video-box {
    width: 66%;
    margin-right: 1rem;
  }
}

.newsroom-container {
  padding-bottom: 3rem;
}

.newsroom-container .news-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #939598;
  height: 100%;
  background-color: #ffffff;
}

.newsroom-container .news-card .news-img {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  min-height: 21rem;
}

.newsroom-container .news-card .news-content {
  padding: 3rem 2rem 3rem;
}

.newsroom-container .news-card .news-label {
  /*text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;*/
  margin-bottom: 0.5em;
}

.newsroom-container .news-card .news-lead {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 2rem;
}

.newsroom-container .news-card .news-lead p {
  margin-bottom: 0;
}

.news-hero {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  min-height: 22rem;
}

@media (min-width: 1300px) {
  .newsroom-container {
    padding-bottom: 5rem;
  }

  .newsroom-container .news-card {
    flex-direction: row;
  }

  .newsroom-container .news-card .news-img {
    flex: 0 0 40%;
    width: 100%;
    min-height: 38rem;
  }

  .newsroom-container .news-card .news-content {
    padding: 4rem 3.5rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .newsroom-container .news-card .news-label {
    font-size: 2.4rem;
  }

  .newsroom-container .news-card .news-lead {
    -webkit-line-clamp: 4;
    margin-bottom: 2rem;
  }

  .news-hero {
    min-height: 55rem;
  }
}

.gdpr-cookie-notice-modal-cookie-input {
  margin: 4px 0 0 20px !important;
}

h3.gdpr-cookie-notice-modal-cookie-title {
  cursor: pointer;
}

.gdpr-cookie-notice {
  position: fixed;
  background: #333;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 1000;
}

@media (max-width: 768px) {
  .gdpr-cookie-notice {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.gdpr-cookie-notice-loaded .gdpr-cookie-notice {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.gdpr-cookie-notice-description {
  margin: 0;
  padding: 0 16px 0 0;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

@media (max-width: 768px) {
  .gdpr-cookie-notice-description {
    padding: 0;
    margin: 0 0 10px 0;
    font-size: 12px;
    line-height: 18px;
  }
}

.gdpr-cookie-notice-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 0 auto;
  background: none;
  width: auto;
  height: auto;
  padding: 0;
}

.gdpr-cookie-notice-nav-item {
  text-decoration: none;
  display: block;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  color: #fff;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 600;
}

.gdpr-cookie-notice-nav-item:hover {
  text-decoration: none;
}

.gdpr-cookie-notice-nav-item-btn {
  background: #1ad56c;
  border-radius: 3px;
}

.gdpr-cookie-notice-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg"><path d="m1.41 0-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z" fill="#1ad56c"/></svg>');
  z-index: 1001;
  background: rgba(0, 0, 0, 0.4);
  overflow: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 10px;
  display: none;
}

.gdpr-cookie-notice-show-modal .gdpr-cookie-notice-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gdpr-cookie-notice-modal-content {
  width: 470px;
  border-radius: 6px;
  background: #fff;
  margin: auto;
}

.gdpr-cookie-notice-modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f4f5f7;
}

.gdpr-cookie-notice-modal-title {
  font-family: SanFranciscoText-Semibold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  padding: 0;
}

.gdpr-cookie-notice-modal-close {
  width: 24px;
  height: 24px;
  margin: 0 0 0 auto;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.gdpr-cookie-notice-modal-close:before,
.gdpr-cookie-notice-modal-close:after {
  width: 18px;
  height: 2px;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #bdbdbd;
}

.gdpr-cookie-notice-modal-close:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.gdpr-cookie-notice-modal-close:hover {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 1;
}

.gdpr-cookie-notice-modal-cookies {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.gdpr-cookie-notice-modal-cookie {
  display: block;
  border-bottom: 1px solid #f4f5f7;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.gdpr-cookie-notice-modal-cookie-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 16px;
}

.gdpr-cookie-notice-modal-cookie-title {
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 0;
  line-height: 1;
  position: relative;
}

.gdpr-cookie-notice-modal-cookie-title:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #bdbdbd transparent transparent transparent;
  content: "";
  display: block;
  position: absolute;
  right: -17px;
  top: 50%;
  margin-top: -2px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.open .gdpr-cookie-notice-modal-cookie-title:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.gdpr-cookie-notice-modal-cookie-state {
  color: #1ad56c;
  margin-left: auto;
  font-size: 15px;
}

.gdpr-cookie-notice-modal-cookie-input {
  opacity: 0;
}

.gdpr-cookie-notice-modal-cookie-input:checked + .gdpr-cookie-notice-modal-cookie-input-switch {
  background: #1ad56c;
  -webkit-box-shadow: inset 0px 0px 0px 1px #1ad56c;
  box-shadow: inset 0px 0px 0px 1px #1ad56c;
}

.gdpr-cookie-notice-modal-cookie-input-switch {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-box-shadow: inset 0px 0px 0px 1px #bdbdbd;
  box-shadow: inset 0px 0px 0px 1px #bdbdbd;
  margin: 0 0 0 auto;
  position: relative;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  cursor: pointer;
}

.gdpr-cookie-notice-modal-cookie-input-switch:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: url('data:image/svg+xml;utf8,<svg height="11" viewBox="0 0 14 11" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m5 10.42-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42z" fill="rgb(255,255,255)" fill-rule="evenodd"/></svg>') no-repeat center 7px;
  content: "";
  display: block;
}

.gdpr-cookie-notice-modal-cookie-info {
  margin: 0;
  padding: 0 16px 12px 16px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 16px;
  display: none;
}

.open .gdpr-cookie-notice-modal-cookie-info {
  display: block;
}

.gdpr-cookie-notice-modal-footer {
  background: #f4f5f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 16px 16px 16px;
  border-radius: 0 0 6px 6px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.gdpr-cookie-notice-modal-footer-item {
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: #1ad56c;
  position: relative;
}

.gdpr-cookie-notice-modal-footer-item:after {
  position: absolute;
  right: -16px;
  top: 50%;
  margin-top: -5px;
  background: url('data:image/svg+xml;utf8,<svg height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg"><path d="m1.41 0-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z" fill="#1ad56c"/></svg>') no-repeat left center;
  height: 12px;
  width: 8px;
  content: "";
  display: block;
}

.gdpr-cookie-notice-modal-footer-item-btn {
  padding: 0 16px;
  color: #fff;
  background: #1ad56c;
  border-radius: 3px;
  position: relative;
}

.gdpr-cookie-notice-modal-footer-item-btn span {
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.gdpr-cookie-notice-modal-footer-item-btn:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  content: "";
  opacity: 0;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  display: block;
  background: url('data:image/svg+xml;utf8,<svg height="11" viewBox="0 0 14 11" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m5 10.42-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42z" fill="rgb(255,255,255)" fill-rule="evenodd"/></svg>') no-repeat center;
}

.gdpr-cookie-notice-modal-footer-item-btn.saved span {
  opacity: 0;
}

.gdpr-cookie-notice-modal-footer-item-btn.saved:after {
  opacity: 1;
}

.gdpr-cookie-notice-modal-footer-item:hover {
  text-decoration: none;
  opacity: 1;
}

.location-link {
  text-decoration: underline;
  color: #3FA922;
}

.ticket-btn {
  margin: 10px 0;
}

.no-top-marg {
  margin-top: 0px;
}

.red {
  color: red !important;
}

.old-price {
  text-decoration: line-through;
}

.alert-danger {
  color: red;
}

.no-top-pad {
  padding-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

