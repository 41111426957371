.hero-wo-conf-container {
    position: relative;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 7.8rem);
    padding-top: 6.9rem;
    overflow: hidden;

    h1 {
        font-size: 4.2rem;
        font-weight: 900;
        line-height: 1em;
        color: $green;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    h2 {
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 1.3em;
        color: $black;
    }
}

.hero-container {
    background-color: $bgcolor;

    .hero-top {
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        height: 39rem;
        padding: 4.5rem 0;

        h1 {
            font-size: 3rem;
            font-weight: 300;
            color: $white;
            margin-bottom: .05em;
            line-height: 1.6em;

            span {
                background-color: rgba($color: $black2, $alpha: .8);
                padding: .15em .3em;
                box-decoration-break: clone;
            }
        }
    
        h2 {
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 2.1em;
            color: $white;
            margin-bottom: 0;

            .bg {
                background-color: rgba($color: $green, $alpha: .8);
                padding: .4em .6em;
                box-decoration-break: clone;
            }

            .wp-now {
                white-space: nowrap;
            }
        }
    }

    .hero-bottom {
        padding: 4.5rem 0;
    }

    h3 {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.9rem;
        margin-bottom: 2rem;
        color: $black;
    }

    .conf-cat,
    .conf-date {
        color: $black;
    }

    .date {
        color: $black;
        font-weight: 300;
        display: flex;
        margin-bottom: 2rem;

        &>div {
            width: 4.8rem;
            margin-right: 2rem;
        }

        .num {
            font-size: 4rem;
            line-height: 5rem;
        }

        .num-label {
            text-transform: uppercase;
            font-size: .8rem;
        }
    }

    .nums-container {
        color: $black;
        font-weight: 300;
        display: flex;
        margin-bottom: 2rem;

        .nums {
            width: 6.4rem;
            height: 6.1rem;
            border: 1px solid $black;
            background-color: $white;
            border-radius: 1rem 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 1.2rem;
            margin-right: 1rem;

            .num {
                font-size: 2.7rem;
                line-height: 3.3rem;
            }

            .num-label {
                font-weight: 500;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                font-size: .6rem;
            }
        }
    }

    .nums_cta {
        .cta {
            display: inline-flex;
            flex-direction: column;
        }

        .reg {
            padding: 1.5rem 1.3rem;
            border-bottom-right-radius: 0;
        }

        .program {
            background-color: $white;
            border: 1px solid $black;
            font-size: 1rem;
            padding: 1.5rem 1rem;
            border-top-left-radius: 0;
            white-space: nowrap;
            color: $black;

            .icon-angle-right {
                vertical-align: top;
                font-size: 1rem;
                margin-left: 1rem;
            }

            &:hover, &:focus {
                background-color: $bgcolor;
                filter: brightness(1);
            }
        }
    }
}

// scroll down

.scroll-down {
    position: absolute;
    max-width: $row-width;
    width: 100%;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    padding-left: 3rem;
    padding-right: 3rem;
    color: $white;

    h4 {
        font-size: .8rem;
        margin-bottom: 1rem;
    }

    i {
        display: block;
        font-size: 1rem;
        animation: sdb04 2s infinite;
        transform: translateY(0);
        position: relative;
    }
}

@keyframes sdb04 {
    0% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(10px);
    }

    40% {
        transform: translateY(0);
    }
}

@media (min-width: $row-width) {

    .hero-wo-conf-container {
        padding-top: clamp(7rem, 7.3vw, 14rem);
        height: calc(100vh - 9rem);

        h1 {
            font-size: clamp(6rem, 5.3vw, 10rem);
            margin-bottom: 0.2em;
            max-width: 70%;
        }

        h2 {
            font-size: clamp(3rem, 2.6vw, 4.9rem);
            max-width: 55%;
        }
    }

    .hero-container {
        height: calc(100vh - 9rem);
        overflow: hidden;

        .hero-top {
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: cover;
            height: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 0;
    
            h1 {
                font-size: clamp(5rem, 3.9vw, 7.5rem);
            }
    
            h2 {
                font-size: clamp(2rem, 2vw, 3.8rem);
                max-width: 70%;
            }
        }

        .hero-bottom {
            height: 50%;
            display: flex;
            align-items: center;
            padding: 0;
        }

        h3 {
            font-size: clamp(1.6rem, 1.3vw, 2.4rem);
            line-height: 1.2em;
            margin-bottom: clamp(2rem, 1.6vw, 3rem);
        }

        .date_cta,
        .nums_cta {
            display: flex;
            align-items: center;
        }

        .date {
            color: $white;
            margin-right: 15rem;
            margin-bottom: 0;

            &>div {
                width: 8rem;
                margin-right: 3rem;
            }

            .num {
                font-size: 6rem;
                line-height: 7rem;
            }

            .num-label {
                font-size: 1.2rem;
            }
        }

        .nums-container {
            margin-right: 9rem;
            margin-bottom: 0;
    
            .nums {
                width: clamp(6.4rem, 8.2vw, 15.6rem);
                height: clamp(6.1rem, 7.9vw, 15rem);
                border: 2px solid $black;
                border-radius: 2rem 0;
                padding: 0 clamp(1.2rem, 1.6vw, 3rem);
                margin-right: 3rem;
    
                .num {
                    font-size: clamp(4rem, 3.4vw, 6.6rem);
                    line-height: 1.2em;
                }
    
                .num-label {
                    font-size: clamp(0.4rem, 0.65vw, 1.3rem);
                }
            }
        }
    
        .nums_cta {
            .reg {
                padding: clamp(1.3rem, 1.3vw, 2.5rem);
            }
    
            .program {
                font-size: clamp(1rem, 1.1vw, 2rem);
                padding: clamp(1.3rem, 1.3vw, 2.5rem);
    
                .icon-angle-right {
                    font-size: 1rem;
                    margin-left: 1rem;
                }
            }
        }
    }

    .header-contact-container {
        bottom: 2rem;
        right: 3.5rem;
        font-size: 1.3rem;

        a {
            margin: 1.5rem 0;
        }
    }

    .scroll-down {
        bottom: 2rem;

        h4 {
            font-size: 1.2rem;
            margin-bottom: 2rem;
        }

        i {
            font-size: 1.4rem;
        }
    }
}

@media (min-width: 1500px) {
    .header-contact-container {
        left: 3.5rem;
        right: unset;
    }
}