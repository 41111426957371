.videos-container {
    position: relative;
    margin: $mobile-margin 0;
    overflow: hidden;

    .flickity-prev-next-button.previous {
        left: -.3rem;
    }
    
    .flickity-prev-next-button.next {
        right: -.3rem;
    }

    .video-box {
        width: 85%;
        margin-right: .6rem;
        opacity: .3;
        transition: opacity .3s;
        pointer-events: none;

        &.is-selected {
            opacity: 1;
            pointer-events: auto;
        }
    }

    .video-responsive {
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }
}

@media (min-width: $row-width) {
    .videos-container {
        margin: $desktop-margin 0;

        .flickity-prev-next-button.previous {
            left: 3rem;
        }
        
        .flickity-prev-next-button.next {
            right: 3rem;
        }

        .video-box {
            width: 66%;
            margin-right: 1rem;
        }
    }
}