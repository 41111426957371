.thanks-404-container {
    position: relative;
    background-color: $green;
    padding: 4rem 0 3rem;
    color: $white;

    h1 {
        text-transform: uppercase;
    }

    .big-hl {
        font-size: 6rem;
        line-height: 1em;
        font-weight: 900;
        margin-bottom: 0;
    }

    p {
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 500;
    }
}

@media (min-width: $row-width) {
    .thanks-404-container {
        padding: 12rem 0 9rem;

        .big-hl {
            font-size: 12rem;
        }

        p {
            font-size: 2.4rem;
            line-height: 2.9rem;
        }
    }
}