.reg-cta-container {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: $green;
    padding: 6rem 0;

    .reg-cta-wraper {
        position: relative;
        max-width: 95rem;
        width: 100%;
        padding: 0 3rem;

        .reg-cta-content {
            position: relative;
            z-index: 1;
            padding: 5rem 5rem 3rem;
            width: 100%;
            margin: -7.6rem 0 0 0;
            background-color: $white;
            color: $white;

            h2 {
                font-size: 2.5rem;
                line-height: 3rem;
                font-weight: 500;
                margin-bottom: 2.5rem;
                color: $green;
                text-transform: uppercase;
            }

            p {
                font-size: 1.2rem;
                line-height: 1.5rem;
                font-weight: 300;
                margin-bottom: 2.5rem;
                color: $black;
            }
        }

        .reg-cta-img {
            position: relative;
            margin: 0 -1.5rem;
            width: calc(100% + 3rem);
            height: 18.2rem;
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}

@media (min-width: $row-width) {
    .reg-cta-container {
        padding: 11rem 0;

        .reg-cta-wraper {
            position: relative;
            width: 100%;
            padding: 0;
    
            .reg-cta-content {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 9rem 7rem 4rem;
                margin: 0;
                width: 45.2rem;
    
                h2 {
                    font-size: 3.6rem;
                    line-height: 4.4rem;
                    margin-bottom: 3.5rem;
                }
    
                p {
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    margin-bottom: 3.5rem;
                }
            }
    
            .reg-cta-img {
                margin: 0 0 0 auto;
                width: 60.8rem;
                height: 42rem;
            }
        }
    }
}