// color

.white {
    color: $white !important;
}

.blue {
    color: $blue !important;
}

.green {
    color: $green !important;
}

.black {
    color: $black !important;
}

// bg-color

.white-bg {
    background-color: $white !important;
}

.blue-bg {
    background-color: $blue !important;
}

.green-bg {
    background-color: $green !important;
}

.grey-bg {
    background-color: $grey !important;
}

.black-bg {
    background-color: $black !important;
}
