.flickity-page-dots .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background: $white;
    border-radius: 50%;
    border: 1px solid $green;
    cursor: pointer;
    opacity: 1;
}

.flickity-page-dots .dot.is-selected {
    background: $green;
}

.flickity-button {
    position: absolute;
    background: none;
    border: none;
    color: #333;
}

.flickity-button:hover {
    background: none;
    cursor: pointer;
}

.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
    fill: $green;
}

.flickity-prev-next-button.previous {
    left: -2rem;
}

.flickity-prev-next-button.next {
    right: -2rem;
}

@media (min-width: $row-width) {
    .flickity-prev-next-button.previous {
        left: -3rem;
    }
    
    .flickity-prev-next-button.next {
        right: -3rem;
    }
}