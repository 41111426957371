.reg-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    h2 {
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: .5em;
    }

    .reg-sale-end {
        background-color: $green;
        padding: 3rem 2rem 2.5rem;
        margin: 0 0 4rem;
        color: $white;
        border-radius: 2rem 0;

        .date {
            color: $white;
            font-weight: 300;
            display: flex;

            &>div {
                margin-right: 2rem;
            }

            .num {
                font-size: 4rem;
                line-height: 5rem;
            }

            .num-label {
                text-transform: uppercase;
                font-size: .8rem;
            }
        }
    }

    .reg-form {
        background-color: $lightgreen;
        padding: 3rem 3rem 3.5rem;
        margin: 0 -3rem 3rem;

        .form-label, .person-hl {
            font-size: 2.2rem;
            font-weight: 700;
            margin-bottom: 2rem;
        }

        .mp-top {
            margin-top: 1.5em;
            display: flex;
            justify-content: space-between;
        }

        .remove-clone {
            font-size: 1.4rem;
            line-height: 1.3em;
            margin-left: 2rem;
        
            &:hover {
                color: $green;
            }
        }
    }

    .reg-conf-rule {
        background-color: rgba($color: $blue, $alpha: .25);
        padding: 4rem 2rem 3.5rem;
        margin: 0;

        .rules {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            i {
                display: block;
                font-size: 2rem;
                flex: 0 0 5rem;
                text-align: center;
                margin-right: 1rem;
            }

            .rule-desc {
                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    form {
        .ticket-selector {
            .ticket-btn {
                width: 100%;
                height: 5rem;
                position: relative;

                label {
                    color: $white;
                }
            }

            label,
            input {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            input[type="radio"] {
                opacity: 0.011;
                z-index: 100;
            }

            input[type="radio"]:checked+label {
                background: $green;
            }

            label {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                z-index: 90;
                padding: 1rem;
                text-align: center;
                color: $white;
                font-size: 1.4rem;
                background-color: rgba($color: $grey, $alpha: .5);
                border-radius: 1rem 0;
                transition: background-color .3s, color .3s, filter .3s;
                filter: brightness(1);
                margin-bottom: 0;
            }

            label:hover {
                filter: brightness(1.1);
            }
        }

        input[type],
        .form-control,
        .custom-control-label::before,
        .custom-control-label::after {
            border: 1px solid $darkgrey;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            border: 1px solid $darkgrey;
        }

        .select-width {
            width: 100%;
        }

        hr {
            margin-top: 0;
            border: 0;
            border-top: 2px solid $green;
        }
    }

    .subtotal {
        color: $black;
        font-size: 1.6rem;
        text-transform: uppercase;

        .subtotal-num {
            text-align: right;

            span {
                font-weight: 700;
            }
        }
    }

    .total {
        padding: 2.3rem 2.5rem;
        background-color: $green;
        color: $white;
        font-size: 3rem;
        text-align: right;
    }

    .simplepay-img {
        margin: 4rem 0;
    }
}

.price-row {
    font-size: 1.6rem;
}

.payment-container {
    margin: 0 0 3rem;
}

@media (min-width: $row-width) {
    .reg-container {
        margin: 0 0 7rem;
        padding: 0;

        &::after {
            content: "";
            clear: both;
            display: table;
        }

        .reg-sale-end {
            padding: 5.5rem 4rem 7rem;
            float: right;
            max-width: 43.5%;
            width: 100%;
            margin: 0 0 1.5rem;

            .date {

                &>div {
                    margin-right: 3rem;
                }

                .num {
                    font-size: 6rem;
                    line-height: 7rem;
                }

                .num-label {
                    font-size: 1.2rem;
                }
            }
        }

        .ticket-sale {
            float: left;
            max-width: 55%;
        }

        .reg-form {
            padding: 3.5rem 4.5rem 4rem;
            width: 100%;
            margin: 0 0 1.5rem;

            .form-label, .person-hl {
                font-size: 3rem;
                margin-bottom: 4.5rem;
            }

            .remove-clone {
                font-size: 2rem;
            }
        }

        .reg-conf-rule {
            padding: 4rem 4rem;
            float: right;
            max-width: 43.5%;
            width: 100%;
            margin: 0;

            .rules {
                margin-bottom: 2rem;

                i {
                    font-size: 2.4rem;
                    flex: 0 0 6rem;
                    margin-right: 1.5rem;
                }
            }
        }

        form {

            .ticket-selector {
                .ticket-btn {
                    width: 100%;
                    height: 8rem;
                }
    
                label {
                    font-size: 2.4rem;
                    border-radius: 2rem 0;
                }
            }
    
            .select-width {
                width: 60%;
            }

            .subtotal {
                font-size: 2.3rem;
                font-weight: 500;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }

            .total {
                padding: 2.3rem 2.5rem;
                font-size: 3rem;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .simplepay-img {
            margin: 5rem 0 6rem;
        }
    }

    .price-row {
        font-size: 1.8rem;
    }

    .payment-container {
        margin: 0 0 7rem;
    }
}