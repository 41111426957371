.newsroom-container {
    padding-bottom: 3rem;

    .news-card {
        display: flex;
        flex-direction: column;
        border: 1px solid $grey;
        height: 100%;
        background-color: $white;

        .news-img {
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: cover;
            width: 100%;
            min-height: 21rem;
        }

        .news-content {
            padding: 3rem 2rem 3rem;
        }

        .news-label {
            /*text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;*/
            margin-bottom: .5em;
        }

        .news-lead {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin-bottom: 2rem;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.news-hero {
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
    min-height: 22rem;
}

@media (min-width: $row-width) {
    .newsroom-container {
        padding-bottom: 5rem;

        .news-card {
            flex-direction: row;

            .news-img {
                flex: 0 0 40%;
                width: 100%;
                min-height: 38rem;
            }

            .news-content {
                padding: 4rem 3.5rem 5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }

            .news-label {
                //-webkit-line-clamp: 4;
                font-size: 2.4rem;
            }

            .news-lead {
                -webkit-line-clamp: 4;
                margin-bottom: 2rem;
            }
        }
    }

    .news-hero {
        min-height: 55rem;
    }
}