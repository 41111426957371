.documents-container {
    position: relative;
    margin: $mobile-margin 0;

    .document-link {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-weight: 400;
        margin-bottom: 1rem;

        i {
            font-size: 1.5rem;
            margin-right: 1rem;
            color: $green;
        }

        a {
            display: flex;
        }

        a:hover, a:focus {
            color: $green;
        }
    }
}

@media (min-width: $row-width) {
    .documents-container {
        margin: $desktop-margin 0;

        .document-link {
            font-size: 2.4rem;
            line-height: 2.9rem;
            margin-bottom: 0;

            i {
                font-size: 1.8rem;
                margin-right: 1.5rem;
            }
        }

        .documents-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 16px;
        }
    }
}