.location-link {
  text-decoration: underline;
  color: #3FA922;

}

.ticket-btn {
  margin: 10px 0;
}

.no-top-marg {
  margin-top: 0px;
}

.red {
  color: red !important;
}

.old-price {
  text-decoration: line-through;
}

.alert-danger {
  color: red;
}

.no-top-pad {
  padding-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}
