.program-container {
    position: relative;
    margin: $mobile-margin 0;

    h3 {
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    h4 {
        margin-bottom: 1rem;
    }

    h4, p {
        font-size: 1.2rem;
    }

    .program-box {
        display: flex;
        margin: 0 -1.5rem 1.5rem;
        width: calc(100% + 3rem);
    }

    .program-img {
        width: 6rem;
        height: 11rem;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .program-data {
        flex: 1;
        padding: 1.5rem 1.5rem;
        background-color: $blue;
        color: $white;

        .less {
            display: none;
        }
    }

    .program-desc {
        //display: none;
        padding-left: 1.5rem;
        border-left: 2px solid $white;
    }

    .more-desc-btn {
        text-transform: uppercase;
        display: inline-block;
        font-size: 1rem;
        line-height: 1.4rem;

        i {
            display: inline-block;
            margin-right: 1rem;
        }

        &.back {
            i {
                transform: rotate(180deg);
            }
        }
    }

    .more-desc {
        display: none;
        margin-top: 2rem;
    }
}

@media (min-width: $row-width) {
    .program-container {
        margin: $desktop-margin 0;

        h3 {
            margin-bottom: 1rem;
        }
    
        h4 {
            margin-bottom: 3rem;
        }
    
        h4, p {
            font-size: 1.6rem;
        }
    
        .program-box {
            margin: 0 0 2rem;
            width: 100%;
        }
    
        .program-img {
            width: 29.6rem;
            height: 27rem;
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    
        .program-data {
            padding: 4rem 6rem 3rem;
        }
    
        .program-desc {
            //display: block;
            padding-left: 3rem;
            border-left: 2px solid $white;
        }
    
        .more-desc-btn {
            font-size: 1.2rem;
            line-height: 1.5rem;
    
            i {
                margin-right: 1.5rem;
            }
        }
    
        .more-desc {
            margin-top: 3rem;
        }
    }
}