.conferences-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 6rem 0 0;

    .ch-container {
        position: relative;
        max-width: 1630px;
        width: 100%;
        padding: 0 3rem;

        .ch-content {
            position: relative;
            z-index: 1;
            padding: 3rem 2rem;
            width: 100%;
            margin: -7.6rem 0 0 0;
            background-color: $green;
            color: $white;

            h1 {
                font-size: 3.6rem;
                line-height: 3.6rem;
                font-weight: 900;
                margin-bottom: 1.8rem;
                text-transform: uppercase;
            }

            p {
                font-size: 1.6rem;
                line-height: 1.9rem;
                margin-bottom: 1.5rem;
            }

            .conf-info {
                margin-bottom: 2.3rem;

                .date {
                    font-weight: 600;
                }
    
                .desc {
                    font-weight: 400;
                }
            }
        }

        .ch-img {
            position: relative;
            margin: 0 -1.5rem;
            width: calc(100% + 3rem);
            height: 28rem;
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}

@media (min-width: $row-width) {
    .conferences-header {
        margin: 4rem 0 0;

        .ch-container {
    
            .ch-content {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 6rem 16rem 6.5rem;
                margin: 0;
                width: 107rem;
    
                h1 {
                    font-size: 10rem;
                    line-height: 10rem;
                    margin-bottom: 5rem;
                }
    
                p {
                    font-size: 2.4rem;
                    line-height: 2.9rem;
                    margin-bottom: 0;
                }
    
                .conf-info {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4rem;
        
                    .date {
                        padding-right: 4rem;
                        border-right: 2px solid $white;
                    }
        
                    .desc {
                        padding-left: 4rem;
                        max-width: 47rem;
                    }
                }
            }
    
            .ch-img {
                margin: 0 0 0 auto;
                width: 107rem;
                height: 76.2rem;
            }
        }
    }
}