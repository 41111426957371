p, blockquote {
    font-weight: 400;
    font-size: 1.6rem;
}

h1 {
    font-weight: 300;
    font-size: 2.4rem;
}

h2 {
    font-weight: 900;
    font-size: 2.2rem;
}

h3 {
    font-weight: 700;
    font-size: 2rem;
}

p {
    line-height: 1.4em;
    margin-bottom: 1.4em;
}

ul {
    margin-bottom: 1.4em;
}

li {
    font-weight: 400;
    font-size: 1.6rem;
}

h1, h2, h3 {
    line-height: 1.2em;
    margin-bottom: 1.4em;
}

.light {
    font-weight: 300 !important;
}

.regular {
    font-weight: 400 !important;
}

.medium {
    font-weight: 500 !important;
}

.bold {
    font-weight: 700 !important;
}

.black-900 {
    font-weight: 900 !important;
}

.uppercase {
    text-transform: uppercase;
}

blockquote {
    margin: 2em 2em 2em 0;
    border-left: 2px solid $green;
    padding: 0 0 0 1em;
    font-style: italic;
    color: $grey;
}

.wider-heading {
    max-width: $wider-width;
    width: 100%;
    margin: 0 auto;
    //padding: 0 1.5rem;

    h1 {
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 1.5em;
    }
}

.basic-heading {
    padding: 4rem 0 3rem;

    h1 {
        font-size: 3.7rem;
        line-height: 4.5rem;
        font-weight: 900;
        color: $green;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}

.basic-text-container {
    padding: 0 0 3rem;

    .editor-js-block {
        overflow-x:auto;
    }

    p a {
        color: $green;

        &:hover {
            color: $black;
        }
    }

    h1, h2, h3, h4 {
        color: $green;
    }

    h1, h2, h3 {
        margin-top: 1.4em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 0;
        }
    }

    h1 {
        margin-bottom: .5rem;
    }

    h2 {
        font-weight: 300;
    }

    .bigger-margin-bottom {
        margin-bottom: 3rem;
    }

    td {
        font-size: 1.4rem;
        padding: 1rem;
        background-color: rgba($color: $grey, $alpha: .1);
        border: 2px solid $white;
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    img, table {
        margin: 1em 0 2em;
    }
}

@media (min-width: $row-width) {
    p, blockquote {
        font-size: 1.8rem;
    }
    
    h1 {
        font-size: 4.9rem;
    }
    
    h2 {
        font-size: 3.6rem;
    }
    
    h3 {
        font-size: 2.4rem;
    }

    li {
        font-size: 1.8rem;
    }

    blockquote {
        margin: 2em 2em 2em 1.5em;
        padding: 0 0 0 1.5em;
        border-left: 3px solid $green;
    }

    .wider-heading {
        //padding: 0 3rem;
    }

    .basic-heading {
        margin: 0 auto;
        padding: 6.5rem 0 4rem;
    
        h1 {
            font-size: 7rem;
            line-height: 1em;
        }
    }

    .basic-text-container {    
        h1 {
            margin-bottom: .5rem;
        }
    
        .bigger-margin-bottom {
            margin-bottom: 5rem;
        }

        td {
            font-size: 1.8rem;
            padding: 2rem;
        }
    }
}
