.sponsors-container {
    position: relative;
    margin: $mobile-margin 0;
    overflow: hidden;

    .sponsors-slider {
        margin: 0 -1.5rem;
        width: calc(100% + 3rem);
    }

    .sponsor-img {
        max-width: calc(50% - 5rem);
        width: 100%;
        height: 9rem;
        margin: 0 2.5rem;

        img {
            display: block;
            max-width: 100%;
            height: 9rem;
            margin: 0 auto;
            object-fit: contain;
        }
    }
}

@media (min-width: $row-width) {
    .sponsors-container {
        margin: $desktop-margin 0;

        .sponsors-slider {
            margin: 0;
            width: 100%;
        }
    
        .sponsor-img {
            max-width: calc(20% - 5rem);
            height: 22rem;
    
            img {
                height: 22rem;
            }
        }
    }
}