.contact-form-container {
    padding: 7rem 0 4rem;
    background-color: $green;
    margin-top: 19rem;

    .chameleon {
        position: absolute;
        top: -23.5rem;
        left: -6rem;
        width: 42rem;
        pointer-events: none;

        img {
            width: 100%;
            height: auto;
        }
    }

    h2 {
        color: $white;
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 1rem;

        span {
            font-size: 5rem;
            font-weight: 900;
        }
    }

    h3 {
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 700;
        max-width: 29.5rem;
        margin-bottom: 3.5rem;
    }

    form {
        .form-group {
            margin-bottom: 3rem;
        }

        label {
            color: $white;
        }

        .custom-control-label {
            font-size: 1.4rem;
        }
    }
}

@media (min-width: $row-width) {
    .contact-form-container {
        padding: 9.5rem 0 8.8rem;

        .chameleon {
            top: -42.9rem;
            left: -11.5rem;
            width: 84.8rem;
        }
    
        h2 {
            font-size: 3.6rem;
            margin-bottom: 1.5rem;
    
            span {
                font-size: 7rem;
            }
        }
    
        h3 {
            font-size: 2rem;
            line-height: 2.4rem;
            max-width: 35rem;
            margin-bottom: 0;
        }

        form {    
            .form-group {
                margin-bottom: 3rem;
            }

            .custom-control-label {
                font-size: 1.8rem;
            }
        }
    }
}