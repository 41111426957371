.container-fluid {
    padding-left: 3rem !important;
    padding-right: 3rem !important;

    .row {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important;

        [class*="col-"] {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
        }

        .form-row {
            margin-right: -.8rem !important;
            margin-left: -.8rem !important;
        }

        .form-row>.col,
        .form-row>[class*=col-] {
            padding-right: .8rem !important;
            padding-left: .8rem !important;
        }
    }
}

@media (min-width: $row-width) {
    .container-fluid {
        max-width: $row-width;
        padding-left: 3rem !important;
        padding-right: 3rem !important;

        .row {
            margin-left: -3rem !important;
            margin-right: -3rem !important;

            [class*="col-"] {
                padding-left: 3rem !important;
                padding-right: 3rem !important;
            }

            &.no-gutters {
                margin-right: 0 !important;
                margin-left: 0 !important;

                >.col,
                >[class*="col-"] {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
            }
        }

        &.min-padding {
            .row {
                margin-left: -1.5rem !important;
                margin-right: -1.5rem !important;

                [class*="col-"] {
                    padding-left: 1.5rem !important;
                    padding-right: 1.5rem !important;
                }
            }
        }
    }
}