.search-container {
    margin-bottom: 4rem;
}

.search-padding-top {
    padding-top: 6rem;
}

@media (min-width: $row-width) {
    .search-container {
        margin-bottom: 6rem;
    }

    .search-padding-top {
        padding-top: 8rem;
    }
}